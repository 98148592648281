<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Link View</h4>
                <div>
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name </label>
                <b-form-input v-model="applicationData.name" @blur="applicationData.name.$touch()"></b-form-input>

              </b-col>
              <b-col cols="4">
                <label>Grouping</label>
                <b-form-select v-model="applicationData.grouping">
                  <b-form-select-option v-for="(item, index) in grouping" :key="index" :value="item" @blur="item.groupingID.$touch()">{{item.name}}</b-form-select-option>
                </b-form-select>           
              </b-col>

              <b-col cols="4">
                <label>Target</label>
<!--                <b-form-input v-model="applicationData.target" @blur="applicationData.target.$touch()"></b-form-input>-->
                <b-form-select v-model="applicationData.target" :options="targetOptions"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Type</label>
                <!--                <b-form-input v-model="applicationData.target" @blur="applicationData.target.$touch()"></b-form-input>-->
                <b-form-select v-model="applicationData.type" :options="typeOptions"></b-form-select>
              </b-col>
              <b-col cols="4">
                <label v-if="applicationData.type === 'powerbi'">PowerBi Desktop Guid</label>
                <label v-if="applicationData.type !== 'powerbi'">Url </label>
                <b-form-input v-model="applicationData.url" @blur="applicationData.url.$touch()"></b-form-input>
              </b-col>
              <b-col cols="4" v-if="applicationData.type === 'powerbi'">
                <label>PowerBi Mobile Guid </label>
                <b-form-input v-model="applicationData.powerBiMobile" @blur="applicationData.url.$touch()"></b-form-input>
              </b-col>
               <b-col cols="4">
                <label>List Order </label>
                <b-form-input  type="number" v-model="applicationData.listOrder" @blur="applicationData.listOrder.$touch()"></b-form-input>
              </b-col>

              <b-col cols="4">
                <label>Sidebar Icon <b-icon :icon="applicationData.sidebarIcon"></b-icon></label>

              <b-form-select v-model="applicationData.sidebarIcon" class="mb-3">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <b-form-select-option value="BIcon123">123</b-form-select-option>
                <b-form-select-option value="BIconActivity">Activity</b-form-select-option>
                <b-form-select-option value="BIconAlarmFill">AlarmFill</b-form-select-option>
                <b-form-select-option value="BIconAlarm">Alarm</b-form-select-option>
                <b-form-select-option value="BIconAlignBottom">AlignBottom</b-form-select-option>
                <b-form-select-option value="BIconAlignCenter">AlignCenter</b-form-select-option>
                <b-form-select-option value="BIconAlignEnd">AlignEnd</b-form-select-option>
                <b-form-select-option value="BIconAlignMiddle">AlignMiddle</b-form-select-option>
                <b-form-select-option value="BIconAlignStart">AlignStart</b-form-select-option>
                <b-form-select-option value="BIconAlignTop">AlignTop</b-form-select-option>
                <b-form-select-option value="BIconAlt">Alt</b-form-select-option>
                <b-form-select-option value="BIconAppIndicator">AppIndicator</b-form-select-option>
                <b-form-select-option value="BIconApp">App</b-form-select-option>
                <b-form-select-option value="BIconApple">Apple</b-form-select-option>
                <b-form-select-option value="BIconArchiveFill">ArchiveFill</b-form-select-option>
                <b-form-select-option value="BIconArchive">Archive</b-form-select-option>
                <b-form-select-option value="BIconArrow90degDown">Arrow90degDown</b-form-select-option>
                <b-form-select-option value="BIconArrow90degLeft">Arrow90degLeft</b-form-select-option>
                <b-form-select-option value="BIconArrow90degRight">Arrow90degRight</b-form-select-option>
                <b-form-select-option value="BIconArrow90degUp">Arrow90degUp</b-form-select-option>
                <b-form-select-option value="BIconArrowBarDown">ArrowBarDown</b-form-select-option>
                <b-form-select-option value="BIconArrowBarLeft">ArrowBarLeft</b-form-select-option>
                <b-form-select-option value="BIconArrowBarRight">ArrowBarRight</b-form-select-option>
                <b-form-select-option value="BIconArrowBarUp">ArrowBarUp</b-form-select-option>
                <b-form-select-option value="BIconArrowClockwise">ArrowClockwise</b-form-select-option>
                <b-form-select-option value="BIconArrowCounterclockwise">ArrowCounterclockwise</b-form-select-option>
                <b-form-select-option value="BIconArrowDownCircleFill">ArrowDownCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownCircle">ArrowDownCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowDownLeftCircleFill">ArrowDownLeftCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownLeftCircle">ArrowDownLeftCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowDownLeftSquareFill">ArrowDownLeftSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownLeftSquare">ArrowDownLeftSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowDownLeft">ArrowDownLeft</b-form-select-option>
                <b-form-select-option value="BIconArrowDownRightCircleFill">ArrowDownRightCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownRightCircle">ArrowDownRightCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowDownRightSquareFill">ArrowDownRightSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownRightSquare">ArrowDownRightSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowDownRight">ArrowDownRight</b-form-select-option>
                <b-form-select-option value="BIconArrowDownShort">ArrowDownShort</b-form-select-option>
                <b-form-select-option value="BIconArrowDownSquareFill">ArrowDownSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowDownSquare">ArrowDownSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowDownUp">ArrowDownUp</b-form-select-option>
                <b-form-select-option value="BIconArrowDown">ArrowDown</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftCircleFill">ArrowLeftCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftCircle">ArrowLeftCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftRight">ArrowLeftRight</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftShort">ArrowLeftShort</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftSquareFill">ArrowLeftSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowLeftSquare">ArrowLeftSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowLeft">ArrowLeft</b-form-select-option>
                <b-form-select-option value="BIconArrowRepeat">ArrowRepeat</b-form-select-option>
                <b-form-select-option value="BIconArrowReturnLeft">ArrowReturnLeft</b-form-select-option>
                <b-form-select-option value="BIconArrowReturnRight">ArrowReturnRight</b-form-select-option>
                <b-form-select-option value="BIconArrowRightCircleFill">ArrowRightCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowRightCircle">ArrowRightCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowRightShort">ArrowRightShort</b-form-select-option>
                <b-form-select-option value="BIconArrowRightSquareFill">ArrowRightSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowRightSquare">ArrowRightSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowRight">ArrowRight</b-form-select-option>
                <b-form-select-option value="BIconArrowUpCircleFill">ArrowUpCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpCircle">ArrowUpCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowUpLeftCircleFill">ArrowUpLeftCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpLeftCircle">ArrowUpLeftCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowUpLeftSquareFill">ArrowUpLeftSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpLeftSquare">ArrowUpLeftSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowUpLeft">ArrowUpLeft</b-form-select-option>
                <b-form-select-option value="BIconArrowUpRightCircleFill">ArrowUpRightCircleFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpRightCircle">ArrowUpRightCircle</b-form-select-option>
                <b-form-select-option value="BIconArrowUpRightSquareFill">ArrowUpRightSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpRightSquare">ArrowUpRightSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowUpRight">ArrowUpRight</b-form-select-option>
                <b-form-select-option value="BIconArrowUpShort">ArrowUpShort</b-form-select-option>
                <b-form-select-option value="BIconArrowUpSquareFill">ArrowUpSquareFill</b-form-select-option>
                <b-form-select-option value="BIconArrowUpSquare">ArrowUpSquare</b-form-select-option>
                <b-form-select-option value="BIconArrowUp">ArrowUp</b-form-select-option>
                <b-form-select-option value="BIconArrowsAngleContract">ArrowsAngleContract</b-form-select-option>
                <b-form-select-option value="BIconArrowsAngleExpand">ArrowsAngleExpand</b-form-select-option>
                <b-form-select-option value="BIconArrowsCollapse">ArrowsCollapse</b-form-select-option>
                <b-form-select-option value="BIconArrowsExpand">ArrowsExpand</b-form-select-option>
                <b-form-select-option value="BIconArrowsFullscreen">ArrowsFullscreen</b-form-select-option>
                <b-form-select-option value="BIconArrowsMove">ArrowsMove</b-form-select-option>
                <b-form-select-option value="BIconAspectRatioFill">AspectRatioFill</b-form-select-option>
                <b-form-select-option value="BIconAspectRatio">AspectRatio</b-form-select-option>
                <b-form-select-option value="BIconAsterisk">Asterisk</b-form-select-option>
                <b-form-select-option value="BIconAt">At</b-form-select-option>
                <b-form-select-option value="BIconAwardFill">AwardFill</b-form-select-option>
                <b-form-select-option value="BIconAward">Award</b-form-select-option>
                <b-form-select-option value="BIconBack">Back</b-form-select-option>
                <b-form-select-option value="BIconBackspaceFill">BackspaceFill</b-form-select-option>
                <b-form-select-option value="BIconBackspaceReverseFill">BackspaceReverseFill</b-form-select-option>
                <b-form-select-option value="BIconBackspaceReverse">BackspaceReverse</b-form-select-option>
                <b-form-select-option value="BIconBackspace">Backspace</b-form-select-option>
                <b-form-select-option value="BIconBadge3dFill">Badge3dFill</b-form-select-option>
                <b-form-select-option value="BIconBadge3d">Badge3d</b-form-select-option>
                <b-form-select-option value="BIconBadge4kFill">Badge4kFill</b-form-select-option>
                <b-form-select-option value="BIconBadge4k">Badge4k</b-form-select-option>
                <b-form-select-option value="BIconBadge8kFill">Badge8kFill</b-form-select-option>
                <b-form-select-option value="BIconBadge8k">Badge8k</b-form-select-option>
                <b-form-select-option value="BIconBadgeAdFill">BadgeAdFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeAd">BadgeAd</b-form-select-option>
                <b-form-select-option value="BIconBadgeArFill">BadgeArFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeAr">BadgeAr</b-form-select-option>
                <b-form-select-option value="BIconBadgeCcFill">BadgeCcFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeCc">BadgeCc</b-form-select-option>
                <b-form-select-option value="BIconBadgeHdFill">BadgeHdFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeHd">BadgeHd</b-form-select-option>
                <b-form-select-option value="BIconBadgeTmFill">BadgeTmFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeTm">BadgeTm</b-form-select-option>
                <b-form-select-option value="BIconBadgeVoFill">BadgeVoFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeVo">BadgeVo</b-form-select-option>
                <b-form-select-option value="BIconBadgeVrFill">BadgeVrFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeVr">BadgeVr</b-form-select-option>
                <b-form-select-option value="BIconBadgeWcFill">BadgeWcFill</b-form-select-option>
                <b-form-select-option value="BIconBadgeWc">BadgeWc</b-form-select-option>
                <b-form-select-option value="BIconBagCheckFill">BagCheckFill</b-form-select-option>
                <b-form-select-option value="BIconBagCheck">BagCheck</b-form-select-option>
                <b-form-select-option value="BIconBagDashFill">BagDashFill</b-form-select-option>
                <b-form-select-option value="BIconBagDash">BagDash</b-form-select-option>
                <b-form-select-option value="BIconBagFill">BagFill</b-form-select-option>
                <b-form-select-option value="BIconBagPlusFill">BagPlusFill</b-form-select-option>
                <b-form-select-option value="BIconBagPlus">BagPlus</b-form-select-option>
                <b-form-select-option value="BIconBagXFill">BagXFill</b-form-select-option>
                <b-form-select-option value="BIconBagX">BagX</b-form-select-option>
                <b-form-select-option value="BIconBag">Bag</b-form-select-option>
                <b-form-select-option value="BIconBandaidFill">BandaidFill</b-form-select-option>
                <b-form-select-option value="BIconBandaid">Bandaid</b-form-select-option>
                <b-form-select-option value="BIconBank">Bank</b-form-select-option>
                <b-form-select-option value="BIconBank2">Bank2</b-form-select-option>
                <b-form-select-option value="BIconBarChartFill">BarChartFill</b-form-select-option>
                <b-form-select-option value="BIconBarChartLineFill">BarChartLineFill</b-form-select-option>
                <b-form-select-option value="BIconBarChartLine">BarChartLine</b-form-select-option>
                <b-form-select-option value="BIconBarChartSteps">BarChartSteps</b-form-select-option>
                <b-form-select-option value="BIconBarChart">BarChart</b-form-select-option>
                <b-form-select-option value="BIconBasketFill">BasketFill</b-form-select-option>
                <b-form-select-option value="BIconBasket">Basket</b-form-select-option>
                <b-form-select-option value="BIconBasket2Fill">Basket2Fill</b-form-select-option>
                <b-form-select-option value="BIconBasket2">Basket2</b-form-select-option>
                <b-form-select-option value="BIconBasket3Fill">Basket3Fill</b-form-select-option>
                <b-form-select-option value="BIconBasket3">Basket3</b-form-select-option>
                <b-form-select-option value="BIconBatteryCharging">BatteryCharging</b-form-select-option>
                <b-form-select-option value="BIconBatteryFull">BatteryFull</b-form-select-option>
                <b-form-select-option value="BIconBatteryHalf">BatteryHalf</b-form-select-option>
                <b-form-select-option value="BIconBattery">Battery</b-form-select-option>
                <b-form-select-option value="BIconBehance">Behance</b-form-select-option>
                <b-form-select-option value="BIconBellFill">BellFill</b-form-select-option>
                <b-form-select-option value="BIconBellSlashFill">BellSlashFill</b-form-select-option>
                <b-form-select-option value="BIconBellSlash">BellSlash</b-form-select-option>
                <b-form-select-option value="BIconBell">Bell</b-form-select-option>
                <b-form-select-option value="BIconBezier">Bezier</b-form-select-option>
                <b-form-select-option value="BIconBezier2">Bezier2</b-form-select-option>
                <b-form-select-option value="BIconBicycle">Bicycle</b-form-select-option>
                <b-form-select-option value="BIconBinocularsFill">BinocularsFill</b-form-select-option>
                <b-form-select-option value="BIconBinoculars">Binoculars</b-form-select-option>
                <b-form-select-option value="BIconBlockquoteLeft">BlockquoteLeft</b-form-select-option>
                <b-form-select-option value="BIconBlockquoteRight">BlockquoteRight</b-form-select-option>
                <b-form-select-option value="BIconBluetooth">Bluetooth</b-form-select-option>
                <b-form-select-option value="BIconBodyText">BodyText</b-form-select-option>
                <b-form-select-option value="BIconBookFill">BookFill</b-form-select-option>
                <b-form-select-option value="BIconBookHalf">BookHalf</b-form-select-option>
                <b-form-select-option value="BIconBook">Book</b-form-select-option>
                <b-form-select-option value="BIconBookmarkCheckFill">BookmarkCheckFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkCheck">BookmarkCheck</b-form-select-option>
                <b-form-select-option value="BIconBookmarkDashFill">BookmarkDashFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkDash">BookmarkDash</b-form-select-option>
                <b-form-select-option value="BIconBookmarkFill">BookmarkFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkHeartFill">BookmarkHeartFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkHeart">BookmarkHeart</b-form-select-option>
                <b-form-select-option value="BIconBookmarkPlusFill">BookmarkPlusFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkPlus">BookmarkPlus</b-form-select-option>
                <b-form-select-option value="BIconBookmarkStarFill">BookmarkStarFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkStar">BookmarkStar</b-form-select-option>
                <b-form-select-option value="BIconBookmarkXFill">BookmarkXFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarkX">BookmarkX</b-form-select-option>
                <b-form-select-option value="BIconBookmark">Bookmark</b-form-select-option>
                <b-form-select-option value="BIconBookmarksFill">BookmarksFill</b-form-select-option>
                <b-form-select-option value="BIconBookmarks">Bookmarks</b-form-select-option>
                <b-form-select-option value="BIconBookshelf">Bookshelf</b-form-select-option>
                <b-form-select-option value="BIconBoomboxFill">BoomboxFill</b-form-select-option>
                <b-form-select-option value="BIconBoombox">Boombox</b-form-select-option>
                <b-form-select-option value="BIconBootstrapFill">BootstrapFill</b-form-select-option>
                <b-form-select-option value="BIconBootstrapReboot">BootstrapReboot</b-form-select-option>
                <b-form-select-option value="BIconBootstrap">Bootstrap</b-form-select-option>
                <b-form-select-option value="BIconBorderAll">BorderAll</b-form-select-option>
                <b-form-select-option value="BIconBorderBottom">BorderBottom</b-form-select-option>
                <b-form-select-option value="BIconBorderCenter">BorderCenter</b-form-select-option>
                <b-form-select-option value="BIconBorderInner">BorderInner</b-form-select-option>
                <b-form-select-option value="BIconBorderLeft">BorderLeft</b-form-select-option>
                <b-form-select-option value="BIconBorderMiddle">BorderMiddle</b-form-select-option>
                <b-form-select-option value="BIconBorderOuter">BorderOuter</b-form-select-option>
                <b-form-select-option value="BIconBorderRight">BorderRight</b-form-select-option>
                <b-form-select-option value="BIconBorderStyle">BorderStyle</b-form-select-option>
                <b-form-select-option value="BIconBorderTop">BorderTop</b-form-select-option>
                <b-form-select-option value="BIconBorderWidth">BorderWidth</b-form-select-option>
                <b-form-select-option value="BIconBorder">Border</b-form-select-option>
                <b-form-select-option value="BIconBoundingBoxCircles">BoundingBoxCircles</b-form-select-option>
                <b-form-select-option value="BIconBoundingBox">BoundingBox</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowDownLeft">BoxArrowDownLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowDownRight">BoxArrowDownRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowDown">BoxArrowDown</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInDownLeft">BoxArrowInDownLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInDownRight">BoxArrowInDownRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInDown">BoxArrowInDown</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInLeft">BoxArrowInLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInRight">BoxArrowInRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInUpLeft">BoxArrowInUpLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInUpRight">BoxArrowInUpRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowInUp">BoxArrowInUp</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowLeft">BoxArrowLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowRight">BoxArrowRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowUpLeft">BoxArrowUpLeft</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowUpRight">BoxArrowUpRight</b-form-select-option>
                <b-form-select-option value="BIconBoxArrowUp">BoxArrowUp</b-form-select-option>
                <b-form-select-option value="BIconBoxSeam">BoxSeam</b-form-select-option>
                <b-form-select-option value="BIconBox">Box</b-form-select-option>
                <b-form-select-option value="BIconBoxes">Boxes</b-form-select-option>
                <b-form-select-option value="BIconBraces">Braces</b-form-select-option>
                <b-form-select-option value="BIconBricks">Bricks</b-form-select-option>
                <b-form-select-option value="BIconBriefcaseFill">BriefcaseFill</b-form-select-option>
                <b-form-select-option value="BIconBriefcase">Briefcase</b-form-select-option>
                <b-form-select-option value="BIconBrightnessAltHighFill">BrightnessAltHighFill</b-form-select-option>
                <b-form-select-option value="BIconBrightnessAltHigh">BrightnessAltHigh</b-form-select-option>
                <b-form-select-option value="BIconBrightnessAltLowFill">BrightnessAltLowFill</b-form-select-option>
                <b-form-select-option value="BIconBrightnessAltLow">BrightnessAltLow</b-form-select-option>
                <b-form-select-option value="BIconBrightnessHighFill">BrightnessHighFill</b-form-select-option>
                <b-form-select-option value="BIconBrightnessHigh">BrightnessHigh</b-form-select-option>
                <b-form-select-option value="BIconBrightnessLowFill">BrightnessLowFill</b-form-select-option>
                <b-form-select-option value="BIconBrightnessLow">BrightnessLow</b-form-select-option>
                <b-form-select-option value="BIconBroadcastPin">BroadcastPin</b-form-select-option>
                <b-form-select-option value="BIconBroadcast">Broadcast</b-form-select-option>
                <b-form-select-option value="BIconBrushFill">BrushFill</b-form-select-option>
                <b-form-select-option value="BIconBrush">Brush</b-form-select-option>
                <b-form-select-option value="BIconBucketFill">BucketFill</b-form-select-option>
                <b-form-select-option value="BIconBucket">Bucket</b-form-select-option>
                <b-form-select-option value="BIconBugFill">BugFill</b-form-select-option>
                <b-form-select-option value="BIconBug">Bug</b-form-select-option>
                <b-form-select-option value="BIconBuilding">Building</b-form-select-option>
                <b-form-select-option value="BIconBullseye">Bullseye</b-form-select-option>
                <b-form-select-option value="BIconCalculatorFill">CalculatorFill</b-form-select-option>
                <b-form-select-option value="BIconCalculator">Calculator</b-form-select-option>
                <b-form-select-option value="BIconCalendarCheckFill">CalendarCheckFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarCheck">CalendarCheck</b-form-select-option>
                <b-form-select-option value="BIconCalendarDateFill">CalendarDateFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarDate">CalendarDate</b-form-select-option>
                <b-form-select-option value="BIconCalendarDayFill">CalendarDayFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarDay">CalendarDay</b-form-select-option>
                <b-form-select-option value="BIconCalendarEventFill">CalendarEventFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarEvent">CalendarEvent</b-form-select-option>
                <b-form-select-option value="BIconCalendarFill">CalendarFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarMinusFill">CalendarMinusFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarMinus">CalendarMinus</b-form-select-option>
                <b-form-select-option value="BIconCalendarMonthFill">CalendarMonthFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarMonth">CalendarMonth</b-form-select-option>
                <b-form-select-option value="BIconCalendarPlusFill">CalendarPlusFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarPlus">CalendarPlus</b-form-select-option>
                <b-form-select-option value="BIconCalendarRangeFill">CalendarRangeFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarRange">CalendarRange</b-form-select-option>
                <b-form-select-option value="BIconCalendarWeekFill">CalendarWeekFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarWeek">CalendarWeek</b-form-select-option>
                <b-form-select-option value="BIconCalendarXFill">CalendarXFill</b-form-select-option>
                <b-form-select-option value="BIconCalendarX">CalendarX</b-form-select-option>
                <b-form-select-option value="BIconCalendar">Calendar</b-form-select-option>
                <b-form-select-option value="BIconCalendar2CheckFill">Calendar2CheckFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Check">Calendar2Check</b-form-select-option>
                <b-form-select-option value="BIconCalendar2DateFill">Calendar2DateFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Date">Calendar2Date</b-form-select-option>
                <b-form-select-option value="BIconCalendar2DayFill">Calendar2DayFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Day">Calendar2Day</b-form-select-option>
                <b-form-select-option value="BIconCalendar2EventFill">Calendar2EventFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Event">Calendar2Event</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Fill">Calendar2Fill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2MinusFill">Calendar2MinusFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Minus">Calendar2Minus</b-form-select-option>
                <b-form-select-option value="BIconCalendar2MonthFill">Calendar2MonthFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Month">Calendar2Month</b-form-select-option>
                <b-form-select-option value="BIconCalendar2PlusFill">Calendar2PlusFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Plus">Calendar2Plus</b-form-select-option>
                <b-form-select-option value="BIconCalendar2RangeFill">Calendar2RangeFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Range">Calendar2Range</b-form-select-option>
                <b-form-select-option value="BIconCalendar2WeekFill">Calendar2WeekFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2Week">Calendar2Week</b-form-select-option>
                <b-form-select-option value="BIconCalendar2XFill">Calendar2XFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar2X">Calendar2X</b-form-select-option>
                <b-form-select-option value="BIconCalendar2">Calendar2</b-form-select-option>
                <b-form-select-option value="BIconCalendar3EventFill">Calendar3EventFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar3Event">Calendar3Event</b-form-select-option>
                <b-form-select-option value="BIconCalendar3Fill">Calendar3Fill</b-form-select-option>
                <b-form-select-option value="BIconCalendar3RangeFill">Calendar3RangeFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar3Range">Calendar3Range</b-form-select-option>
                <b-form-select-option value="BIconCalendar3WeekFill">Calendar3WeekFill</b-form-select-option>
                <b-form-select-option value="BIconCalendar3Week">Calendar3Week</b-form-select-option>
                <b-form-select-option value="BIconCalendar3">Calendar3</b-form-select-option>
                <b-form-select-option value="BIconCalendar4Event">Calendar4Event</b-form-select-option>
                <b-form-select-option value="BIconCalendar4Range">Calendar4Range</b-form-select-option>
                <b-form-select-option value="BIconCalendar4Week">Calendar4Week</b-form-select-option>
                <b-form-select-option value="BIconCalendar4">Calendar4</b-form-select-option>
                <b-form-select-option value="BIconCameraFill">CameraFill</b-form-select-option>
                <b-form-select-option value="BIconCameraReelsFill">CameraReelsFill</b-form-select-option>
                <b-form-select-option value="BIconCameraReels">CameraReels</b-form-select-option>
                <b-form-select-option value="BIconCameraVideoFill">CameraVideoFill</b-form-select-option>
                <b-form-select-option value="BIconCameraVideoOffFill">CameraVideoOffFill</b-form-select-option>
                <b-form-select-option value="BIconCameraVideoOff">CameraVideoOff</b-form-select-option>
                <b-form-select-option value="BIconCameraVideo">CameraVideo</b-form-select-option>
                <b-form-select-option value="BIconCamera">Camera</b-form-select-option>
                <b-form-select-option value="BIconCamera2">Camera2</b-form-select-option>
                <b-form-select-option value="BIconCapslockFill">CapslockFill</b-form-select-option>
                <b-form-select-option value="BIconCapslock">Capslock</b-form-select-option>
                <b-form-select-option value="BIconCardChecklist">CardChecklist</b-form-select-option>
                <b-form-select-option value="BIconCardHeading">CardHeading</b-form-select-option>
                <b-form-select-option value="BIconCardImage">CardImage</b-form-select-option>
                <b-form-select-option value="BIconCardList">CardList</b-form-select-option>
                <b-form-select-option value="BIconCardText">CardText</b-form-select-option>
                <b-form-select-option value="BIconCaretDownFill">CaretDownFill</b-form-select-option>
                <b-form-select-option value="BIconCaretDownSquareFill">CaretDownSquareFill</b-form-select-option>
                <b-form-select-option value="BIconCaretDownSquare">CaretDownSquare</b-form-select-option>
                <b-form-select-option value="BIconCaretDown">CaretDown</b-form-select-option>
                <b-form-select-option value="BIconCaretLeftFill">CaretLeftFill</b-form-select-option>
                <b-form-select-option value="BIconCaretLeftSquareFill">CaretLeftSquareFill</b-form-select-option>
                <b-form-select-option value="BIconCaretLeftSquare">CaretLeftSquare</b-form-select-option>
                <b-form-select-option value="BIconCaretLeft">CaretLeft</b-form-select-option>
                <b-form-select-option value="BIconCaretRightFill">CaretRightFill</b-form-select-option>
                <b-form-select-option value="BIconCaretRightSquareFill">CaretRightSquareFill</b-form-select-option>
                <b-form-select-option value="BIconCaretRightSquare">CaretRightSquare</b-form-select-option>
                <b-form-select-option value="BIconCaretRight">CaretRight</b-form-select-option>
                <b-form-select-option value="BIconCaretUpFill">CaretUpFill</b-form-select-option>
                <b-form-select-option value="BIconCaretUpSquareFill">CaretUpSquareFill</b-form-select-option>
                <b-form-select-option value="BIconCaretUpSquare">CaretUpSquare</b-form-select-option>
                <b-form-select-option value="BIconCaretUp">CaretUp</b-form-select-option>
                <b-form-select-option value="BIconCartCheckFill">CartCheckFill</b-form-select-option>
                <b-form-select-option value="BIconCartCheck">CartCheck</b-form-select-option>
                <b-form-select-option value="BIconCartDashFill">CartDashFill</b-form-select-option>
                <b-form-select-option value="BIconCartDash">CartDash</b-form-select-option>
                <b-form-select-option value="BIconCartFill">CartFill</b-form-select-option>
                <b-form-select-option value="BIconCartPlusFill">CartPlusFill</b-form-select-option>
                <b-form-select-option value="BIconCartPlus">CartPlus</b-form-select-option>
                <b-form-select-option value="BIconCartXFill">CartXFill</b-form-select-option>
                <b-form-select-option value="BIconCartX">CartX</b-form-select-option>
                <b-form-select-option value="BIconCart">Cart</b-form-select-option>
                <b-form-select-option value="BIconCart2">Cart2</b-form-select-option>
                <b-form-select-option value="BIconCart3">Cart3</b-form-select-option>
                <b-form-select-option value="BIconCart4">Cart4</b-form-select-option>
                <b-form-select-option value="BIconCashCoin">CashCoin</b-form-select-option>
                <b-form-select-option value="BIconCashStack">CashStack</b-form-select-option>
                <b-form-select-option value="BIconCash">Cash</b-form-select-option>
                <b-form-select-option value="BIconCast">Cast</b-form-select-option>
                <b-form-select-option value="BIconChatDotsFill">ChatDotsFill</b-form-select-option>
                <b-form-select-option value="BIconChatDots">ChatDots</b-form-select-option>
                <b-form-select-option value="BIconChatFill">ChatFill</b-form-select-option>
                <b-form-select-option value="BIconChatLeftDotsFill">ChatLeftDotsFill</b-form-select-option>
                <b-form-select-option value="BIconChatLeftDots">ChatLeftDots</b-form-select-option>
                <b-form-select-option value="BIconChatLeftFill">ChatLeftFill</b-form-select-option>
                <b-form-select-option value="BIconChatLeftQuoteFill">ChatLeftQuoteFill</b-form-select-option>
                <b-form-select-option value="BIconChatLeftQuote">ChatLeftQuote</b-form-select-option>
                <b-form-select-option value="BIconChatLeftTextFill">ChatLeftTextFill</b-form-select-option>
                <b-form-select-option value="BIconChatLeftText">ChatLeftText</b-form-select-option>
                <b-form-select-option value="BIconChatLeft">ChatLeft</b-form-select-option>
                <b-form-select-option value="BIconChatQuoteFill">ChatQuoteFill</b-form-select-option>
                <b-form-select-option value="BIconChatQuote">ChatQuote</b-form-select-option>
                <b-form-select-option value="BIconChatRightDotsFill">ChatRightDotsFill</b-form-select-option>
                <b-form-select-option value="BIconChatRightDots">ChatRightDots</b-form-select-option>
                <b-form-select-option value="BIconChatRightFill">ChatRightFill</b-form-select-option>
                <b-form-select-option value="BIconChatRightQuoteFill">ChatRightQuoteFill</b-form-select-option>
                <b-form-select-option value="BIconChatRightQuote">ChatRightQuote</b-form-select-option>
                <b-form-select-option value="BIconChatRightTextFill">ChatRightTextFill</b-form-select-option>
                <b-form-select-option value="BIconChatRightText">ChatRightText</b-form-select-option>
                <b-form-select-option value="BIconChatRight">ChatRight</b-form-select-option>
                <b-form-select-option value="BIconChatSquareDotsFill">ChatSquareDotsFill</b-form-select-option>
                <b-form-select-option value="BIconChatSquareDots">ChatSquareDots</b-form-select-option>
                <b-form-select-option value="BIconChatSquareFill">ChatSquareFill</b-form-select-option>
                <b-form-select-option value="BIconChatSquareQuoteFill">ChatSquareQuoteFill</b-form-select-option>
                <b-form-select-option value="BIconChatSquareQuote">ChatSquareQuote</b-form-select-option>
                <b-form-select-option value="BIconChatSquareTextFill">ChatSquareTextFill</b-form-select-option>
                <b-form-select-option value="BIconChatSquareText">ChatSquareText</b-form-select-option>
                <b-form-select-option value="BIconChatSquare">ChatSquare</b-form-select-option>
                <b-form-select-option value="BIconChatTextFill">ChatTextFill</b-form-select-option>
                <b-form-select-option value="BIconChatText">ChatText</b-form-select-option>
                <b-form-select-option value="BIconChat">Chat</b-form-select-option>
                <b-form-select-option value="BIconCheckAll">CheckAll</b-form-select-option>
                <b-form-select-option value="BIconCheckCircleFill">CheckCircleFill</b-form-select-option>
                <b-form-select-option value="BIconCheckCircle">CheckCircle</b-form-select-option>
                <b-form-select-option value="BIconCheckLg">CheckLg</b-form-select-option>
                <b-form-select-option value="BIconCheckSquareFill">CheckSquareFill</b-form-select-option>
                <b-form-select-option value="BIconCheckSquare">CheckSquare</b-form-select-option>
                <b-form-select-option value="BIconCheck">Check</b-form-select-option>
                <b-form-select-option value="BIconCheck2All">Check2All</b-form-select-option>
                <b-form-select-option value="BIconCheck2Circle">Check2Circle</b-form-select-option>
                <b-form-select-option value="BIconCheck2Square">Check2Square</b-form-select-option>
                <b-form-select-option value="BIconCheck2">Check2</b-form-select-option>
                <b-form-select-option value="BIconChevronBarContract">ChevronBarContract</b-form-select-option>
                <b-form-select-option value="BIconChevronBarDown">ChevronBarDown</b-form-select-option>
                <b-form-select-option value="BIconChevronBarExpand">ChevronBarExpand</b-form-select-option>
                <b-form-select-option value="BIconChevronBarLeft">ChevronBarLeft</b-form-select-option>
                <b-form-select-option value="BIconChevronBarRight">ChevronBarRight</b-form-select-option>
                <b-form-select-option value="BIconChevronBarUp">ChevronBarUp</b-form-select-option>
                <b-form-select-option value="BIconChevronCompactDown">ChevronCompactDown</b-form-select-option>
                <b-form-select-option value="BIconChevronCompactLeft">ChevronCompactLeft</b-form-select-option>
                <b-form-select-option value="BIconChevronCompactRight">ChevronCompactRight</b-form-select-option>
                <b-form-select-option value="BIconChevronCompactUp">ChevronCompactUp</b-form-select-option>
                <b-form-select-option value="BIconChevronContract">ChevronContract</b-form-select-option>
                <b-form-select-option value="BIconChevronDoubleDown">ChevronDoubleDown</b-form-select-option>
                <b-form-select-option value="BIconChevronDoubleLeft">ChevronDoubleLeft</b-form-select-option>
                <b-form-select-option value="BIconChevronDoubleRight">ChevronDoubleRight</b-form-select-option>
                <b-form-select-option value="BIconChevronDoubleUp">ChevronDoubleUp</b-form-select-option>
                <b-form-select-option value="BIconChevronDown">ChevronDown</b-form-select-option>
                <b-form-select-option value="BIconChevronExpand">ChevronExpand</b-form-select-option>
                <b-form-select-option value="BIconChevronLeft">ChevronLeft</b-form-select-option>
                <b-form-select-option value="BIconChevronRight">ChevronRight</b-form-select-option>
                <b-form-select-option value="BIconChevronUp">ChevronUp</b-form-select-option>
                <b-form-select-option value="BIconCircleFill">CircleFill</b-form-select-option>
                <b-form-select-option value="BIconCircleHalf">CircleHalf</b-form-select-option>
                <b-form-select-option value="BIconCircleSquare">CircleSquare</b-form-select-option>
                <b-form-select-option value="BIconCircle">Circle</b-form-select-option>
                <b-form-select-option value="BIconClipboardCheck">ClipboardCheck</b-form-select-option>
                <b-form-select-option value="BIconClipboardData">ClipboardData</b-form-select-option>
                <b-form-select-option value="BIconClipboardMinus">ClipboardMinus</b-form-select-option>
                <b-form-select-option value="BIconClipboardPlus">ClipboardPlus</b-form-select-option>
                <b-form-select-option value="BIconClipboardX">ClipboardX</b-form-select-option>
                <b-form-select-option value="BIconClipboard">Clipboard</b-form-select-option>
                <b-form-select-option value="BIconClockFill">ClockFill</b-form-select-option>
                <b-form-select-option value="BIconClockHistory">ClockHistory</b-form-select-option>
                <b-form-select-option value="BIconClock">Clock</b-form-select-option>
                <b-form-select-option value="BIconCloudArrowDownFill">CloudArrowDownFill</b-form-select-option>
                <b-form-select-option value="BIconCloudArrowDown">CloudArrowDown</b-form-select-option>
                <b-form-select-option value="BIconCloudArrowUpFill">CloudArrowUpFill</b-form-select-option>
                <b-form-select-option value="BIconCloudArrowUp">CloudArrowUp</b-form-select-option>
                <b-form-select-option value="BIconCloudCheckFill">CloudCheckFill</b-form-select-option>
                <b-form-select-option value="BIconCloudCheck">CloudCheck</b-form-select-option>
                <b-form-select-option value="BIconCloudDownloadFill">CloudDownloadFill</b-form-select-option>
                <b-form-select-option value="BIconCloudDownload">CloudDownload</b-form-select-option>
                <b-form-select-option value="BIconCloudDrizzleFill">CloudDrizzleFill</b-form-select-option>
                <b-form-select-option value="BIconCloudDrizzle">CloudDrizzle</b-form-select-option>
                <b-form-select-option value="BIconCloudFill">CloudFill</b-form-select-option>
                <b-form-select-option value="BIconCloudFogFill">CloudFogFill</b-form-select-option>
                <b-form-select-option value="BIconCloudFog">CloudFog</b-form-select-option>
                <b-form-select-option value="BIconCloudFog2Fill">CloudFog2Fill</b-form-select-option>
                <b-form-select-option value="BIconCloudFog2">CloudFog2</b-form-select-option>
                <b-form-select-option value="BIconCloudHailFill">CloudHailFill</b-form-select-option>
                <b-form-select-option value="BIconCloudHail">CloudHail</b-form-select-option>
                <b-form-select-option value="BIconCloudHazeFill">CloudHazeFill</b-form-select-option>
                <b-form-select-option value="BIconCloudHaze">CloudHaze</b-form-select-option>
                <b-form-select-option value="BIconCloudHaze2Fill">CloudHaze2Fill</b-form-select-option>
                <b-form-select-option value="BIconCloudHaze2">CloudHaze2</b-form-select-option>
                <b-form-select-option value="BIconCloudLightningFill">CloudLightningFill</b-form-select-option>
                <b-form-select-option value="BIconCloudLightningRainFill">CloudLightningRainFill</b-form-select-option>
                <b-form-select-option value="BIconCloudLightningRain">CloudLightningRain</b-form-select-option>
                <b-form-select-option value="BIconCloudLightning">CloudLightning</b-form-select-option>
                <b-form-select-option value="BIconCloudMinusFill">CloudMinusFill</b-form-select-option>
                <b-form-select-option value="BIconCloudMinus">CloudMinus</b-form-select-option>
                <b-form-select-option value="BIconCloudMoonFill">CloudMoonFill</b-form-select-option>
                <b-form-select-option value="BIconCloudMoon">CloudMoon</b-form-select-option>
                <b-form-select-option value="BIconCloudPlusFill">CloudPlusFill</b-form-select-option>
                <b-form-select-option value="BIconCloudPlus">CloudPlus</b-form-select-option>
                <b-form-select-option value="BIconCloudRainFill">CloudRainFill</b-form-select-option>
                <b-form-select-option value="BIconCloudRainHeavyFill">CloudRainHeavyFill</b-form-select-option>
                <b-form-select-option value="BIconCloudRainHeavy">CloudRainHeavy</b-form-select-option>
                <b-form-select-option value="BIconCloudRain">CloudRain</b-form-select-option>
                <b-form-select-option value="BIconCloudSlashFill">CloudSlashFill</b-form-select-option>
                <b-form-select-option value="BIconCloudSlash">CloudSlash</b-form-select-option>
                <b-form-select-option value="BIconCloudSleetFill">CloudSleetFill</b-form-select-option>
                <b-form-select-option value="BIconCloudSleet">CloudSleet</b-form-select-option>
                <b-form-select-option value="BIconCloudSnowFill">CloudSnowFill</b-form-select-option>
                <b-form-select-option value="BIconCloudSnow">CloudSnow</b-form-select-option>
                <b-form-select-option value="BIconCloudSunFill">CloudSunFill</b-form-select-option>
                <b-form-select-option value="BIconCloudSun">CloudSun</b-form-select-option>
                <b-form-select-option value="BIconCloudUploadFill">CloudUploadFill</b-form-select-option>
                <b-form-select-option value="BIconCloudUpload">CloudUpload</b-form-select-option>
                <b-form-select-option value="BIconCloud">Cloud</b-form-select-option>
                <b-form-select-option value="BIconCloudsFill">CloudsFill</b-form-select-option>
                <b-form-select-option value="BIconClouds">Clouds</b-form-select-option>
                <b-form-select-option value="BIconCloudyFill">CloudyFill</b-form-select-option>
                <b-form-select-option value="BIconCloudy">Cloudy</b-form-select-option>
                <b-form-select-option value="BIconCodeSlash">CodeSlash</b-form-select-option>
                <b-form-select-option value="BIconCodeSquare">CodeSquare</b-form-select-option>
                <b-form-select-option value="BIconCode">Code</b-form-select-option>
                <b-form-select-option value="BIconCoin">Coin</b-form-select-option>
                <b-form-select-option value="BIconCollectionFill">CollectionFill</b-form-select-option>
                <b-form-select-option value="BIconCollectionPlayFill">CollectionPlayFill</b-form-select-option>
                <b-form-select-option value="BIconCollectionPlay">CollectionPlay</b-form-select-option>
                <b-form-select-option value="BIconCollection">Collection</b-form-select-option>
                <b-form-select-option value="BIconColumnsGap">ColumnsGap</b-form-select-option>
                <b-form-select-option value="BIconColumns">Columns</b-form-select-option>
                <b-form-select-option value="BIconCommand">Command</b-form-select-option>
                <b-form-select-option value="BIconCompassFill">CompassFill</b-form-select-option>
                <b-form-select-option value="BIconCompass">Compass</b-form-select-option>
                <b-form-select-option value="BIconConeStriped">ConeStriped</b-form-select-option>
                <b-form-select-option value="BIconCone">Cone</b-form-select-option>
                <b-form-select-option value="BIconController">Controller</b-form-select-option>
                <b-form-select-option value="BIconCpuFill">CpuFill</b-form-select-option>
                <b-form-select-option value="BIconCpu">Cpu</b-form-select-option>
                <b-form-select-option value="BIconCreditCard2BackFill">CreditCard2BackFill</b-form-select-option>
                <b-form-select-option value="BIconCreditCard2Back">CreditCard2Back</b-form-select-option>
                <b-form-select-option value="BIconCreditCard2FrontFill">CreditCard2FrontFill</b-form-select-option>
                <b-form-select-option value="BIconCreditCard2Front">CreditCard2Front</b-form-select-option>
                <b-form-select-option value="BIconCreditCardFill">CreditCardFill</b-form-select-option>
                <b-form-select-option value="BIconCreditCard">CreditCard</b-form-select-option>
                <b-form-select-option value="BIconCrop">Crop</b-form-select-option>
                <b-form-select-option value="BIconCupFill">CupFill</b-form-select-option>
                <b-form-select-option value="BIconCupStraw">CupStraw</b-form-select-option>
                <b-form-select-option value="BIconCup">Cup</b-form-select-option>
                <b-form-select-option value="BIconCurrencyBitcoin">CurrencyBitcoin</b-form-select-option>
                <b-form-select-option value="BIconCurrencyDollar">CurrencyDollar</b-form-select-option>
                <b-form-select-option value="BIconCurrencyEuro">CurrencyEuro</b-form-select-option>
                <b-form-select-option value="BIconCurrencyExchange">CurrencyExchange</b-form-select-option>
                <b-form-select-option value="BIconCurrencyPound">CurrencyPound</b-form-select-option>
                <b-form-select-option value="BIconCurrencyYen">CurrencyYen</b-form-select-option>
                <b-form-select-option value="BIconCursorFill">CursorFill</b-form-select-option>
                <b-form-select-option value="BIconCursorText">CursorText</b-form-select-option>
                <b-form-select-option value="BIconCursor">Cursor</b-form-select-option>
                <b-form-select-option value="BIconDashCircleDotted">DashCircleDotted</b-form-select-option>
                <b-form-select-option value="BIconDashCircleFill">DashCircleFill</b-form-select-option>
                <b-form-select-option value="BIconDashCircle">DashCircle</b-form-select-option>
                <b-form-select-option value="BIconDashLg">DashLg</b-form-select-option>
                <b-form-select-option value="BIconDashSquareDotted">DashSquareDotted</b-form-select-option>
                <b-form-select-option value="BIconDashSquareFill">DashSquareFill</b-form-select-option>
                <b-form-select-option value="BIconDashSquare">DashSquare</b-form-select-option>
                <b-form-select-option value="BIconDash">Dash</b-form-select-option>
                <b-form-select-option value="BIconDeviceHddFill">DeviceHddFill</b-form-select-option>
                <b-form-select-option value="BIconDeviceHdd">DeviceHdd</b-form-select-option>
                <b-form-select-option value="BIconDeviceSsdFill">DeviceSsdFill</b-form-select-option>
                <b-form-select-option value="BIconDeviceSsd">DeviceSsd</b-form-select-option>
                <b-form-select-option value="BIconDiagram2Fill">Diagram2Fill</b-form-select-option>
                <b-form-select-option value="BIconDiagram2">Diagram2</b-form-select-option>
                <b-form-select-option value="BIconDiagram3Fill">Diagram3Fill</b-form-select-option>
                <b-form-select-option value="BIconDiagram3">Diagram3</b-form-select-option>
                <b-form-select-option value="BIconDiamondFill">DiamondFill</b-form-select-option>
                <b-form-select-option value="BIconDiamondHalf">DiamondHalf</b-form-select-option>
                <b-form-select-option value="BIconDiamond">Diamond</b-form-select-option>
                <b-form-select-option value="BIconDice1Fill">Dice1Fill</b-form-select-option>
                <b-form-select-option value="BIconDice1">Dice1</b-form-select-option>
                <b-form-select-option value="BIconDice2Fill">Dice2Fill</b-form-select-option>
                <b-form-select-option value="BIconDice2">Dice2</b-form-select-option>
                <b-form-select-option value="BIconDice3Fill">Dice3Fill</b-form-select-option>
                <b-form-select-option value="BIconDice3">Dice3</b-form-select-option>
                <b-form-select-option value="BIconDice4Fill">Dice4Fill</b-form-select-option>
                <b-form-select-option value="BIconDice4">Dice4</b-form-select-option>
                <b-form-select-option value="BIconDice5Fill">Dice5Fill</b-form-select-option>
                <b-form-select-option value="BIconDice5">Dice5</b-form-select-option>
                <b-form-select-option value="BIconDice6Fill">Dice6Fill</b-form-select-option>
                <b-form-select-option value="BIconDice6">Dice6</b-form-select-option>
                <b-form-select-option value="BIconDiscFill">DiscFill</b-form-select-option>
                <b-form-select-option value="BIconDisc">Disc</b-form-select-option>
                <b-form-select-option value="BIconDiscord">Discord</b-form-select-option>
                <b-form-select-option value="BIconDisplayFill">DisplayFill</b-form-select-option>
                <b-form-select-option value="BIconDisplay">Display</b-form-select-option>
                <b-form-select-option value="BIconDisplayportFill">DisplayportFill</b-form-select-option>
                <b-form-select-option value="BIconDisplayport">Displayport</b-form-select-option>
                <b-form-select-option value="BIconDistributeHorizontal">DistributeHorizontal</b-form-select-option>
                <b-form-select-option value="BIconDistributeVertical">DistributeVertical</b-form-select-option>
                <b-form-select-option value="BIconDoorClosedFill">DoorClosedFill</b-form-select-option>
                <b-form-select-option value="BIconDoorClosed">DoorClosed</b-form-select-option>
                <b-form-select-option value="BIconDoorOpenFill">DoorOpenFill</b-form-select-option>
                <b-form-select-option value="BIconDoorOpen">DoorOpen</b-form-select-option>
                <b-form-select-option value="BIconDot">Dot</b-form-select-option>
                <b-form-select-option value="BIconDownload">Download</b-form-select-option>
                <b-form-select-option value="BIconDpadFill">DpadFill</b-form-select-option>
                <b-form-select-option value="BIconDpad">Dpad</b-form-select-option>
                <b-form-select-option value="BIconDribbble">Dribbble</b-form-select-option>
                <b-form-select-option value="BIconDropletFill">DropletFill</b-form-select-option>
                <b-form-select-option value="BIconDropletHalf">DropletHalf</b-form-select-option>
                <b-form-select-option value="BIconDroplet">Droplet</b-form-select-option>
                <b-form-select-option value="BIconEarFill">EarFill</b-form-select-option>
                <b-form-select-option value="BIconEar">Ear</b-form-select-option>
                <b-form-select-option value="BIconEarbuds">Earbuds</b-form-select-option>
                <b-form-select-option value="BIconEaselFill">EaselFill</b-form-select-option>
                <b-form-select-option value="BIconEasel">Easel</b-form-select-option>
                <b-form-select-option value="BIconEasel2Fill">Easel2Fill</b-form-select-option>
                <b-form-select-option value="BIconEasel2">Easel2</b-form-select-option>
                <b-form-select-option value="BIconEasel3Fill">Easel3Fill</b-form-select-option>
                <b-form-select-option value="BIconEasel3">Easel3</b-form-select-option>
                <b-form-select-option value="BIconEggFill">EggFill</b-form-select-option>
                <b-form-select-option value="BIconEggFried">EggFried</b-form-select-option>
                <b-form-select-option value="BIconEgg">Egg</b-form-select-option>
                <b-form-select-option value="BIconEjectFill">EjectFill</b-form-select-option>
                <b-form-select-option value="BIconEject">Eject</b-form-select-option>
                <b-form-select-option value="BIconEmojiAngryFill">EmojiAngryFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiAngry">EmojiAngry</b-form-select-option>
                <b-form-select-option value="BIconEmojiDizzyFill">EmojiDizzyFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiDizzy">EmojiDizzy</b-form-select-option>
                <b-form-select-option value="BIconEmojiExpressionlessFill">EmojiExpressionlessFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiExpressionless">EmojiExpressionless</b-form-select-option>
                <b-form-select-option value="BIconEmojiFrownFill">EmojiFrownFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiFrown">EmojiFrown</b-form-select-option>
                <b-form-select-option value="BIconEmojiHeartEyesFill">EmojiHeartEyesFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiHeartEyes">EmojiHeartEyes</b-form-select-option>
                <b-form-select-option value="BIconEmojiLaughingFill">EmojiLaughingFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiLaughing">EmojiLaughing</b-form-select-option>
                <b-form-select-option value="BIconEmojiNeutralFill">EmojiNeutralFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiNeutral">EmojiNeutral</b-form-select-option>
                <b-form-select-option value="BIconEmojiSmileFill">EmojiSmileFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiSmileUpsideDownFill">EmojiSmileUpsideDownFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiSmileUpsideDown">EmojiSmileUpsideDown</b-form-select-option>
                <b-form-select-option value="BIconEmojiSmile">EmojiSmile</b-form-select-option>
                <b-form-select-option value="BIconEmojiSunglassesFill">EmojiSunglassesFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiSunglasses">EmojiSunglasses</b-form-select-option>
                <b-form-select-option value="BIconEmojiWinkFill">EmojiWinkFill</b-form-select-option>
                <b-form-select-option value="BIconEmojiWink">EmojiWink</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeCheckFill">EnvelopeCheckFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeCheck">EnvelopeCheck</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeDashFill">EnvelopeDashFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeDash">EnvelopeDash</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeExclamationFill">EnvelopeExclamationFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeExclamation">EnvelopeExclamation</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeFill">EnvelopeFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeOpenFill">EnvelopeOpenFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeOpen">EnvelopeOpen</b-form-select-option>
                <b-form-select-option value="BIconEnvelopePlusFill">EnvelopePlusFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopePlus">EnvelopePlus</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeSlashFill">EnvelopeSlashFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeSlash">EnvelopeSlash</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeXFill">EnvelopeXFill</b-form-select-option>
                <b-form-select-option value="BIconEnvelopeX">EnvelopeX</b-form-select-option>
                <b-form-select-option value="BIconEnvelope">Envelope</b-form-select-option>
                <b-form-select-option value="BIconEraserFill">EraserFill</b-form-select-option>
                <b-form-select-option value="BIconEraser">Eraser</b-form-select-option>
                <b-form-select-option value="BIconEthernet">Ethernet</b-form-select-option>
                <b-form-select-option value="BIconExclamationCircleFill">ExclamationCircleFill</b-form-select-option>
                <b-form-select-option value="BIconExclamationCircle">ExclamationCircle</b-form-select-option>
                <b-form-select-option value="BIconExclamationDiamondFill">ExclamationDiamondFill</b-form-select-option>
                <b-form-select-option value="BIconExclamationDiamond">ExclamationDiamond</b-form-select-option>
                <b-form-select-option value="BIconExclamationLg">ExclamationLg</b-form-select-option>
                <b-form-select-option value="BIconExclamationOctagonFill">ExclamationOctagonFill</b-form-select-option>
                <b-form-select-option value="BIconExclamationOctagon">ExclamationOctagon</b-form-select-option>
                <b-form-select-option value="BIconExclamationSquareFill">ExclamationSquareFill</b-form-select-option>
                <b-form-select-option value="BIconExclamationSquare">ExclamationSquare</b-form-select-option>
                <b-form-select-option value="BIconExclamationTriangleFill">ExclamationTriangleFill</b-form-select-option>
                <b-form-select-option value="BIconExclamationTriangle">ExclamationTriangle</b-form-select-option>
                <b-form-select-option value="BIconExclamation">Exclamation</b-form-select-option>
                <b-form-select-option value="BIconExclude">Exclude</b-form-select-option>
                <b-form-select-option value="BIconExplicitFill">ExplicitFill</b-form-select-option>
                <b-form-select-option value="BIconExplicit">Explicit</b-form-select-option>
                <b-form-select-option value="BIconEyeFill">EyeFill</b-form-select-option>
                <b-form-select-option value="BIconEyeSlashFill">EyeSlashFill</b-form-select-option>
                <b-form-select-option value="BIconEyeSlash">EyeSlash</b-form-select-option>
                <b-form-select-option value="BIconEye">Eye</b-form-select-option>
                <b-form-select-option value="BIconEyedropper">Eyedropper</b-form-select-option>
                <b-form-select-option value="BIconEyeglasses">Eyeglasses</b-form-select-option>
                <b-form-select-option value="BIconFacebook">Facebook</b-form-select-option>
                <b-form-select-option value="BIconFan">Fan</b-form-select-option>
                <b-form-select-option value="BIconFileArrowDownFill">FileArrowDownFill</b-form-select-option>
                <b-form-select-option value="BIconFileArrowDown">FileArrowDown</b-form-select-option>
                <b-form-select-option value="BIconFileArrowUpFill">FileArrowUpFill</b-form-select-option>
                <b-form-select-option value="BIconFileArrowUp">FileArrowUp</b-form-select-option>
                <b-form-select-option value="BIconFileBarGraphFill">FileBarGraphFill</b-form-select-option>
                <b-form-select-option value="BIconFileBarGraph">FileBarGraph</b-form-select-option>
                <b-form-select-option value="BIconFileBinaryFill">FileBinaryFill</b-form-select-option>
                <b-form-select-option value="BIconFileBinary">FileBinary</b-form-select-option>
                <b-form-select-option value="BIconFileBreakFill">FileBreakFill</b-form-select-option>
                <b-form-select-option value="BIconFileBreak">FileBreak</b-form-select-option>
                <b-form-select-option value="BIconFileCheckFill">FileCheckFill</b-form-select-option>
                <b-form-select-option value="BIconFileCheck">FileCheck</b-form-select-option>
                <b-form-select-option value="BIconFileCodeFill">FileCodeFill</b-form-select-option>
                <b-form-select-option value="BIconFileCode">FileCode</b-form-select-option>
                <b-form-select-option value="BIconFileDiffFill">FileDiffFill</b-form-select-option>
                <b-form-select-option value="BIconFileDiff">FileDiff</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkArrowDownFill">FileEarmarkArrowDownFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkArrowDown">FileEarmarkArrowDown</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkArrowUpFill">FileEarmarkArrowUpFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkArrowUp">FileEarmarkArrowUp</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBarGraphFill">FileEarmarkBarGraphFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBarGraph">FileEarmarkBarGraph</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBinaryFill">FileEarmarkBinaryFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBinary">FileEarmarkBinary</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBreakFill">FileEarmarkBreakFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkBreak">FileEarmarkBreak</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkCheckFill">FileEarmarkCheckFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkCheck">FileEarmarkCheck</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkCodeFill">FileEarmarkCodeFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkCode">FileEarmarkCode</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkDiffFill">FileEarmarkDiffFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkDiff">FileEarmarkDiff</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkEaselFill">FileEarmarkEaselFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkEasel">FileEarmarkEasel</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkExcelFill">FileEarmarkExcelFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkExcel">FileEarmarkExcel</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkFill">FileEarmarkFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkFontFill">FileEarmarkFontFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkFont">FileEarmarkFont</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkImageFill">FileEarmarkImageFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkImage">FileEarmarkImage</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkLockFill">FileEarmarkLockFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkLock">FileEarmarkLock</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkLock2Fill">FileEarmarkLock2Fill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkLock2">FileEarmarkLock2</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMedicalFill">FileEarmarkMedicalFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMedical">FileEarmarkMedical</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMinusFill">FileEarmarkMinusFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMinus">FileEarmarkMinus</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMusicFill">FileEarmarkMusicFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkMusic">FileEarmarkMusic</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPdfFill">FileEarmarkPdfFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPdf">FileEarmarkPdf</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPersonFill">FileEarmarkPersonFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPerson">FileEarmarkPerson</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPlayFill">FileEarmarkPlayFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPlay">FileEarmarkPlay</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPlusFill">FileEarmarkPlusFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPlus">FileEarmarkPlus</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPostFill">FileEarmarkPostFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPost">FileEarmarkPost</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPptFill">FileEarmarkPptFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkPpt">FileEarmarkPpt</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkRichtextFill">FileEarmarkRichtextFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkRichtext">FileEarmarkRichtext</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkRuledFill">FileEarmarkRuledFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkRuled">FileEarmarkRuled</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkSlidesFill">FileEarmarkSlidesFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkSlides">FileEarmarkSlides</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkSpreadsheetFill">FileEarmarkSpreadsheetFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkSpreadsheet">FileEarmarkSpreadsheet</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkTextFill">FileEarmarkTextFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkText">FileEarmarkText</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkWordFill">FileEarmarkWordFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkWord">FileEarmarkWord</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkXFill">FileEarmarkXFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkX">FileEarmarkX</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkZipFill">FileEarmarkZipFill</b-form-select-option>
                <b-form-select-option value="BIconFileEarmarkZip">FileEarmarkZip</b-form-select-option>
                <b-form-select-option value="BIconFileEarmark">FileEarmark</b-form-select-option>
                <b-form-select-option value="BIconFileEaselFill">FileEaselFill</b-form-select-option>
                <b-form-select-option value="BIconFileEasel">FileEasel</b-form-select-option>
                <b-form-select-option value="BIconFileExcelFill">FileExcelFill</b-form-select-option>
                <b-form-select-option value="BIconFileExcel">FileExcel</b-form-select-option>
                <b-form-select-option value="BIconFileFill">FileFill</b-form-select-option>
                <b-form-select-option value="BIconFileFontFill">FileFontFill</b-form-select-option>
                <b-form-select-option value="BIconFileFont">FileFont</b-form-select-option>
                <b-form-select-option value="BIconFileImageFill">FileImageFill</b-form-select-option>
                <b-form-select-option value="BIconFileImage">FileImage</b-form-select-option>
                <b-form-select-option value="BIconFileLockFill">FileLockFill</b-form-select-option>
                <b-form-select-option value="BIconFileLock">FileLock</b-form-select-option>
                <b-form-select-option value="BIconFileLock2Fill">FileLock2Fill</b-form-select-option>
                <b-form-select-option value="BIconFileLock2">FileLock2</b-form-select-option>
                <b-form-select-option value="BIconFileMedicalFill">FileMedicalFill</b-form-select-option>
                <b-form-select-option value="BIconFileMedical">FileMedical</b-form-select-option>
                <b-form-select-option value="BIconFileMinusFill">FileMinusFill</b-form-select-option>
                <b-form-select-option value="BIconFileMinus">FileMinus</b-form-select-option>
                <b-form-select-option value="BIconFileMusicFill">FileMusicFill</b-form-select-option>
                <b-form-select-option value="BIconFileMusic">FileMusic</b-form-select-option>
                <b-form-select-option value="BIconFilePdfFill">FilePdfFill</b-form-select-option>
                <b-form-select-option value="BIconFilePdf">FilePdf</b-form-select-option>
                <b-form-select-option value="BIconFilePersonFill">FilePersonFill</b-form-select-option>
                <b-form-select-option value="BIconFilePerson">FilePerson</b-form-select-option>
                <b-form-select-option value="BIconFilePlayFill">FilePlayFill</b-form-select-option>
                <b-form-select-option value="BIconFilePlay">FilePlay</b-form-select-option>
                <b-form-select-option value="BIconFilePlusFill">FilePlusFill</b-form-select-option>
                <b-form-select-option value="BIconFilePlus">FilePlus</b-form-select-option>
                <b-form-select-option value="BIconFilePostFill">FilePostFill</b-form-select-option>
                <b-form-select-option value="BIconFilePost">FilePost</b-form-select-option>
                <b-form-select-option value="BIconFilePptFill">FilePptFill</b-form-select-option>
                <b-form-select-option value="BIconFilePpt">FilePpt</b-form-select-option>
                <b-form-select-option value="BIconFileRichtextFill">FileRichtextFill</b-form-select-option>
                <b-form-select-option value="BIconFileRichtext">FileRichtext</b-form-select-option>
                <b-form-select-option value="BIconFileRuledFill">FileRuledFill</b-form-select-option>
                <b-form-select-option value="BIconFileRuled">FileRuled</b-form-select-option>
                <b-form-select-option value="BIconFileSlidesFill">FileSlidesFill</b-form-select-option>
                <b-form-select-option value="BIconFileSlides">FileSlides</b-form-select-option>
                <b-form-select-option value="BIconFileSpreadsheetFill">FileSpreadsheetFill</b-form-select-option>
                <b-form-select-option value="BIconFileSpreadsheet">FileSpreadsheet</b-form-select-option>
                <b-form-select-option value="BIconFileTextFill">FileTextFill</b-form-select-option>
                <b-form-select-option value="BIconFileText">FileText</b-form-select-option>
                <b-form-select-option value="BIconFileWordFill">FileWordFill</b-form-select-option>
                <b-form-select-option value="BIconFileWord">FileWord</b-form-select-option>
                <b-form-select-option value="BIconFileXFill">FileXFill</b-form-select-option>
                <b-form-select-option value="BIconFileX">FileX</b-form-select-option>
                <b-form-select-option value="BIconFileZipFill">FileZipFill</b-form-select-option>
                <b-form-select-option value="BIconFileZip">FileZip</b-form-select-option>
                <b-form-select-option value="BIconFile">File</b-form-select-option>
                <b-form-select-option value="BIconFilesAlt">FilesAlt</b-form-select-option>
                <b-form-select-option value="BIconFiles">Files</b-form-select-option>
                <b-form-select-option value="BIconFilm">Film</b-form-select-option>
                <b-form-select-option value="BIconFilterCircleFill">FilterCircleFill</b-form-select-option>
                <b-form-select-option value="BIconFilterCircle">FilterCircle</b-form-select-option>
                <b-form-select-option value="BIconFilterLeft">FilterLeft</b-form-select-option>
                <b-form-select-option value="BIconFilterRight">FilterRight</b-form-select-option>
                <b-form-select-option value="BIconFilterSquareFill">FilterSquareFill</b-form-select-option>
                <b-form-select-option value="BIconFilterSquare">FilterSquare</b-form-select-option>
                <b-form-select-option value="BIconFilter">Filter</b-form-select-option>
                <b-form-select-option value="BIconFingerprint">Fingerprint</b-form-select-option>
                <b-form-select-option value="BIconFlagFill">FlagFill</b-form-select-option>
                <b-form-select-option value="BIconFlag">Flag</b-form-select-option>
                <b-form-select-option value="BIconFlower1">Flower1</b-form-select-option>
                <b-form-select-option value="BIconFlower2">Flower2</b-form-select-option>
                <b-form-select-option value="BIconFlower3">Flower3</b-form-select-option>
                <b-form-select-option value="BIconFolderCheck">FolderCheck</b-form-select-option>
                <b-form-select-option value="BIconFolderFill">FolderFill</b-form-select-option>
                <b-form-select-option value="BIconFolderMinus">FolderMinus</b-form-select-option>
                <b-form-select-option value="BIconFolderPlus">FolderPlus</b-form-select-option>
                <b-form-select-option value="BIconFolderSymlinkFill">FolderSymlinkFill</b-form-select-option>
                <b-form-select-option value="BIconFolderSymlink">FolderSymlink</b-form-select-option>
                <b-form-select-option value="BIconFolderX">FolderX</b-form-select-option>
                <b-form-select-option value="BIconFolder">Folder</b-form-select-option>
                <b-form-select-option value="BIconFolder2Open">Folder2Open</b-form-select-option>
                <b-form-select-option value="BIconFolder2">Folder2</b-form-select-option>
                <b-form-select-option value="BIconFonts">Fonts</b-form-select-option>
                <b-form-select-option value="BIconForwardFill">ForwardFill</b-form-select-option>
                <b-form-select-option value="BIconForward">Forward</b-form-select-option>
                <b-form-select-option value="BIconFront">Front</b-form-select-option>
                <b-form-select-option value="BIconFullscreenExit">FullscreenExit</b-form-select-option>
                <b-form-select-option value="BIconFullscreen">Fullscreen</b-form-select-option>
                <b-form-select-option value="BIconFunnelFill">FunnelFill</b-form-select-option>
                <b-form-select-option value="BIconFunnel">Funnel</b-form-select-option>
                <b-form-select-option value="BIconGearFill">GearFill</b-form-select-option>
                <b-form-select-option value="BIconGearWideConnected">GearWideConnected</b-form-select-option>
                <b-form-select-option value="BIconGearWide">GearWide</b-form-select-option>
                <b-form-select-option value="BIconGear">Gear</b-form-select-option>
                <b-form-select-option value="BIconGem">Gem</b-form-select-option>
                <b-form-select-option value="BIconGenderAmbiguous">GenderAmbiguous</b-form-select-option>
                <b-form-select-option value="BIconGenderFemale">GenderFemale</b-form-select-option>
                <b-form-select-option value="BIconGenderMale">GenderMale</b-form-select-option>
                <b-form-select-option value="BIconGenderTrans">GenderTrans</b-form-select-option>
                <b-form-select-option value="BIconGeoAltFill">GeoAltFill</b-form-select-option>
                <b-form-select-option value="BIconGeoAlt">GeoAlt</b-form-select-option>
                <b-form-select-option value="BIconGeoFill">GeoFill</b-form-select-option>
                <b-form-select-option value="BIconGeo">Geo</b-form-select-option>
                <b-form-select-option value="BIconGiftFill">GiftFill</b-form-select-option>
                <b-form-select-option value="BIconGift">Gift</b-form-select-option>
                <b-form-select-option value="BIconGit">Git</b-form-select-option>
                <b-form-select-option value="BIconGithub">Github</b-form-select-option>
                <b-form-select-option value="BIconGlobe">Globe</b-form-select-option>
                <b-form-select-option value="BIconGlobe2">Globe2</b-form-select-option>
                <b-form-select-option value="BIconGoogle">Google</b-form-select-option>
                <b-form-select-option value="BIconGpuCard">GpuCard</b-form-select-option>
                <b-form-select-option value="BIconGraphDownArrow">GraphDownArrow</b-form-select-option>
                <b-form-select-option value="BIconGraphDown">GraphDown</b-form-select-option>
                <b-form-select-option value="BIconGraphUpArrow">GraphUpArrow</b-form-select-option>
                <b-form-select-option value="BIconGraphUp">GraphUp</b-form-select-option>
                <b-form-select-option value="BIconGrid1x2Fill">Grid1x2Fill</b-form-select-option>
                <b-form-select-option value="BIconGrid1x2">Grid1x2</b-form-select-option>
                <b-form-select-option value="BIconGrid3x2GapFill">Grid3x2GapFill</b-form-select-option>
                <b-form-select-option value="BIconGrid3x2Gap">Grid3x2Gap</b-form-select-option>
                <b-form-select-option value="BIconGrid3x2">Grid3x2</b-form-select-option>
                <b-form-select-option value="BIconGrid3x3GapFill">Grid3x3GapFill</b-form-select-option>
                <b-form-select-option value="BIconGrid3x3Gap">Grid3x3Gap</b-form-select-option>
                <b-form-select-option value="BIconGrid3x3">Grid3x3</b-form-select-option>
                <b-form-select-option value="BIconGridFill">GridFill</b-form-select-option>
                <b-form-select-option value="BIconGrid">Grid</b-form-select-option>
                <b-form-select-option value="BIconGripHorizontal">GripHorizontal</b-form-select-option>
                <b-form-select-option value="BIconGripVertical">GripVertical</b-form-select-option>
                <b-form-select-option value="BIconHammer">Hammer</b-form-select-option>
                <b-form-select-option value="BIconHandIndexFill">HandIndexFill</b-form-select-option>
                <b-form-select-option value="BIconHandIndexThumbFill">HandIndexThumbFill</b-form-select-option>
                <b-form-select-option value="BIconHandIndexThumb">HandIndexThumb</b-form-select-option>
                <b-form-select-option value="BIconHandIndex">HandIndex</b-form-select-option>
                <b-form-select-option value="BIconHandThumbsDownFill">HandThumbsDownFill</b-form-select-option>
                <b-form-select-option value="BIconHandThumbsDown">HandThumbsDown</b-form-select-option>
                <b-form-select-option value="BIconHandThumbsUpFill">HandThumbsUpFill</b-form-select-option>
                <b-form-select-option value="BIconHandThumbsUp">HandThumbsUp</b-form-select-option>
                <b-form-select-option value="BIconHandbagFill">HandbagFill</b-form-select-option>
                <b-form-select-option value="BIconHandbag">Handbag</b-form-select-option>
                <b-form-select-option value="BIconHash">Hash</b-form-select-option>
                <b-form-select-option value="BIconHddFill">HddFill</b-form-select-option>
                <b-form-select-option value="BIconHddNetworkFill">HddNetworkFill</b-form-select-option>
                <b-form-select-option value="BIconHddNetwork">HddNetwork</b-form-select-option>
                <b-form-select-option value="BIconHddRackFill">HddRackFill</b-form-select-option>
                <b-form-select-option value="BIconHddRack">HddRack</b-form-select-option>
                <b-form-select-option value="BIconHddStackFill">HddStackFill</b-form-select-option>
                <b-form-select-option value="BIconHddStack">HddStack</b-form-select-option>
                <b-form-select-option value="BIconHdd">Hdd</b-form-select-option>
                <b-form-select-option value="BIconHdmiFill">HdmiFill</b-form-select-option>
                <b-form-select-option value="BIconHdmi">Hdmi</b-form-select-option>
                <b-form-select-option value="BIconHeadphones">Headphones</b-form-select-option>
                <b-form-select-option value="BIconHeadsetVr">HeadsetVr</b-form-select-option>
                <b-form-select-option value="BIconHeadset">Headset</b-form-select-option>
                <b-form-select-option value="BIconHeartFill">HeartFill</b-form-select-option>
                <b-form-select-option value="BIconHeartHalf">HeartHalf</b-form-select-option>
                <b-form-select-option value="BIconHeart">Heart</b-form-select-option>
                <b-form-select-option value="BIconHeptagonFill">HeptagonFill</b-form-select-option>
                <b-form-select-option value="BIconHeptagonHalf">HeptagonHalf</b-form-select-option>
                <b-form-select-option value="BIconHeptagon">Heptagon</b-form-select-option>
                <b-form-select-option value="BIconHexagonFill">HexagonFill</b-form-select-option>
                <b-form-select-option value="BIconHexagonHalf">HexagonHalf</b-form-select-option>
                <b-form-select-option value="BIconHexagon">Hexagon</b-form-select-option>
                <b-form-select-option value="BIconHourglassBottom">HourglassBottom</b-form-select-option>
                <b-form-select-option value="BIconHourglassSplit">HourglassSplit</b-form-select-option>
                <b-form-select-option value="BIconHourglassTop">HourglassTop</b-form-select-option>
                <b-form-select-option value="BIconHourglass">Hourglass</b-form-select-option>
                <b-form-select-option value="BIconHouseDoorFill">HouseDoorFill</b-form-select-option>
                <b-form-select-option value="BIconHouseDoor">HouseDoor</b-form-select-option>
                <b-form-select-option value="BIconHouseFill">HouseFill</b-form-select-option>
                <b-form-select-option value="BIconHouse">House</b-form-select-option>
                <b-form-select-option value="BIconHr">Hr</b-form-select-option>
                <b-form-select-option value="BIconHurricane">Hurricane</b-form-select-option>
                <b-form-select-option value="BIconHypnotize">Hypnotize</b-form-select-option>
                <b-form-select-option value="BIconImageAlt">ImageAlt</b-form-select-option>
                <b-form-select-option value="BIconImageFill">ImageFill</b-form-select-option>
                <b-form-select-option value="BIconImage">Image</b-form-select-option>
                <b-form-select-option value="BIconImages">Images</b-form-select-option>
                <b-form-select-option value="BIconInboxFill">InboxFill</b-form-select-option>
                <b-form-select-option value="BIconInbox">Inbox</b-form-select-option>
                <b-form-select-option value="BIconInboxesFill">InboxesFill</b-form-select-option>
                <b-form-select-option value="BIconInboxes">Inboxes</b-form-select-option>
                <b-form-select-option value="BIconInfinity">Infinity</b-form-select-option>
                <b-form-select-option value="BIconInfoCircleFill">InfoCircleFill</b-form-select-option>
                <b-form-select-option value="BIconInfoCircle">InfoCircle</b-form-select-option>
                <b-form-select-option value="BIconInfoLg">InfoLg</b-form-select-option>
                <b-form-select-option value="BIconInfoSquareFill">InfoSquareFill</b-form-select-option>
                <b-form-select-option value="BIconInfoSquare">InfoSquare</b-form-select-option>
                <b-form-select-option value="BIconInfo">Info</b-form-select-option>
                <b-form-select-option value="BIconInputCursorText">InputCursorText</b-form-select-option>
                <b-form-select-option value="BIconInputCursor">InputCursor</b-form-select-option>
                <b-form-select-option value="BIconInstagram">Instagram</b-form-select-option>
                <b-form-select-option value="BIconIntersect">Intersect</b-form-select-option>
                <b-form-select-option value="BIconJournalAlbum">JournalAlbum</b-form-select-option>
                <b-form-select-option value="BIconJournalArrowDown">JournalArrowDown</b-form-select-option>
                <b-form-select-option value="BIconJournalArrowUp">JournalArrowUp</b-form-select-option>
                <b-form-select-option value="BIconJournalBookmarkFill">JournalBookmarkFill</b-form-select-option>
                <b-form-select-option value="BIconJournalBookmark">JournalBookmark</b-form-select-option>
                <b-form-select-option value="BIconJournalCheck">JournalCheck</b-form-select-option>
                <b-form-select-option value="BIconJournalCode">JournalCode</b-form-select-option>
                <b-form-select-option value="BIconJournalMedical">JournalMedical</b-form-select-option>
                <b-form-select-option value="BIconJournalMinus">JournalMinus</b-form-select-option>
                <b-form-select-option value="BIconJournalPlus">JournalPlus</b-form-select-option>
                <b-form-select-option value="BIconJournalRichtext">JournalRichtext</b-form-select-option>
                <b-form-select-option value="BIconJournalText">JournalText</b-form-select-option>
                <b-form-select-option value="BIconJournalX">JournalX</b-form-select-option>
                <b-form-select-option value="BIconJournal">Journal</b-form-select-option>
                <b-form-select-option value="BIconJournals">Journals</b-form-select-option>
                <b-form-select-option value="BIconJoystick">Joystick</b-form-select-option>
                <b-form-select-option value="BIconJustifyLeft">JustifyLeft</b-form-select-option>
                <b-form-select-option value="BIconJustifyRight">JustifyRight</b-form-select-option>
                <b-form-select-option value="BIconJustify">Justify</b-form-select-option>
                <b-form-select-option value="BIconKanbanFill">KanbanFill</b-form-select-option>
                <b-form-select-option value="BIconKanban">Kanban</b-form-select-option>
                <b-form-select-option value="BIconKeyFill">KeyFill</b-form-select-option>
                <b-form-select-option value="BIconKey">Key</b-form-select-option>
                <b-form-select-option value="BIconKeyboardFill">KeyboardFill</b-form-select-option>
                <b-form-select-option value="BIconKeyboard">Keyboard</b-form-select-option>
                <b-form-select-option value="BIconLadder">Ladder</b-form-select-option>
                <b-form-select-option value="BIconLampFill">LampFill</b-form-select-option>
                <b-form-select-option value="BIconLamp">Lamp</b-form-select-option>
                <b-form-select-option value="BIconLaptopFill">LaptopFill</b-form-select-option>
                <b-form-select-option value="BIconLaptop">Laptop</b-form-select-option>
                <b-form-select-option value="BIconLayerBackward">LayerBackward</b-form-select-option>
                <b-form-select-option value="BIconLayerForward">LayerForward</b-form-select-option>
                <b-form-select-option value="BIconLayersFill">LayersFill</b-form-select-option>
                <b-form-select-option value="BIconLayersHalf">LayersHalf</b-form-select-option>
                <b-form-select-option value="BIconLayers">Layers</b-form-select-option>
                <b-form-select-option value="BIconLayoutSidebarInsetReverse">LayoutSidebarInsetReverse</b-form-select-option>
                <b-form-select-option value="BIconLayoutSidebarInset">LayoutSidebarInset</b-form-select-option>
                <b-form-select-option value="BIconLayoutSidebarReverse">LayoutSidebarReverse</b-form-select-option>
                <b-form-select-option value="BIconLayoutSidebar">LayoutSidebar</b-form-select-option>
                <b-form-select-option value="BIconLayoutSplit">LayoutSplit</b-form-select-option>
                <b-form-select-option value="BIconLayoutTextSidebarReverse">LayoutTextSidebarReverse</b-form-select-option>
                <b-form-select-option value="BIconLayoutTextSidebar">LayoutTextSidebar</b-form-select-option>
                <b-form-select-option value="BIconLayoutTextWindowReverse">LayoutTextWindowReverse</b-form-select-option>
                <b-form-select-option value="BIconLayoutTextWindow">LayoutTextWindow</b-form-select-option>
                <b-form-select-option value="BIconLayoutThreeColumns">LayoutThreeColumns</b-form-select-option>
                <b-form-select-option value="BIconLayoutWtf">LayoutWtf</b-form-select-option>
                <b-form-select-option value="BIconLifePreserver">LifePreserver</b-form-select-option>
                <b-form-select-option value="BIconLightbulbFill">LightbulbFill</b-form-select-option>
                <b-form-select-option value="BIconLightbulbOffFill">LightbulbOffFill</b-form-select-option>
                <b-form-select-option value="BIconLightbulbOff">LightbulbOff</b-form-select-option>
                <b-form-select-option value="BIconLightbulb">Lightbulb</b-form-select-option>
                <b-form-select-option value="BIconLightningChargeFill">LightningChargeFill</b-form-select-option>
                <b-form-select-option value="BIconLightningCharge">LightningCharge</b-form-select-option>
                <b-form-select-option value="BIconLightningFill">LightningFill</b-form-select-option>
                <b-form-select-option value="BIconLightning">Lightning</b-form-select-option>
                <b-form-select-option value="BIconLine">Line</b-form-select-option>
                <b-form-select-option value="BIconLink45deg">Link45deg</b-form-select-option>
                <b-form-select-option value="BIconLink">Link</b-form-select-option>
                <b-form-select-option value="BIconLinkedin">Linkedin</b-form-select-option>
                <b-form-select-option value="BIconListCheck">ListCheck</b-form-select-option>
                <b-form-select-option value="BIconListColumnsReverse">ListColumnsReverse</b-form-select-option>
                <b-form-select-option value="BIconListColumns">ListColumns</b-form-select-option>
                <b-form-select-option value="BIconListNested">ListNested</b-form-select-option>
                <b-form-select-option value="BIconListOl">ListOl</b-form-select-option>
                <b-form-select-option value="BIconListStars">ListStars</b-form-select-option>
                <b-form-select-option value="BIconListTask">ListTask</b-form-select-option>
                <b-form-select-option value="BIconListUl">ListUl</b-form-select-option>
                <b-form-select-option value="BIconList">List</b-form-select-option>
                <b-form-select-option value="BIconLockFill">LockFill</b-form-select-option>
                <b-form-select-option value="BIconLock">Lock</b-form-select-option>
                <b-form-select-option value="BIconMagic">Magic</b-form-select-option>
                <b-form-select-option value="BIconMailbox">Mailbox</b-form-select-option>
                <b-form-select-option value="BIconMailbox2">Mailbox2</b-form-select-option>
                <b-form-select-option value="BIconMapFill">MapFill</b-form-select-option>
                <b-form-select-option value="BIconMap">Map</b-form-select-option>
                <b-form-select-option value="BIconMarkdownFill">MarkdownFill</b-form-select-option>
                <b-form-select-option value="BIconMarkdown">Markdown</b-form-select-option>
                <b-form-select-option value="BIconMask">Mask</b-form-select-option>
                <b-form-select-option value="BIconMastodon">Mastodon</b-form-select-option>
                <b-form-select-option value="BIconMedium">Medium</b-form-select-option>
                <b-form-select-option value="BIconMegaphoneFill">MegaphoneFill</b-form-select-option>
                <b-form-select-option value="BIconMegaphone">Megaphone</b-form-select-option>
                <b-form-select-option value="BIconMemory">Memory</b-form-select-option>
                <b-form-select-option value="BIconMenuAppFill">MenuAppFill</b-form-select-option>
                <b-form-select-option value="BIconMenuApp">MenuApp</b-form-select-option>
                <b-form-select-option value="BIconMenuButtonFill">MenuButtonFill</b-form-select-option>
                <b-form-select-option value="BIconMenuButtonWideFill">MenuButtonWideFill</b-form-select-option>
                <b-form-select-option value="BIconMenuButtonWide">MenuButtonWide</b-form-select-option>
                <b-form-select-option value="BIconMenuButton">MenuButton</b-form-select-option>
                <b-form-select-option value="BIconMenuDown">MenuDown</b-form-select-option>
                <b-form-select-option value="BIconMenuUp">MenuUp</b-form-select-option>
                <b-form-select-option value="BIconMessenger">Messenger</b-form-select-option>
                <b-form-select-option value="BIconMeta">Meta</b-form-select-option>
                <b-form-select-option value="BIconMicFill">MicFill</b-form-select-option>
                <b-form-select-option value="BIconMicMuteFill">MicMuteFill</b-form-select-option>
                <b-form-select-option value="BIconMicMute">MicMute</b-form-select-option>
                <b-form-select-option value="BIconMic">Mic</b-form-select-option>
                <b-form-select-option value="BIconMicrosoft">Microsoft</b-form-select-option>
                <b-form-select-option value="BIconMinecartLoaded">MinecartLoaded</b-form-select-option>
                <b-form-select-option value="BIconMinecart">Minecart</b-form-select-option>
                <b-form-select-option value="BIconModemFill">ModemFill</b-form-select-option>
                <b-form-select-option value="BIconModem">Modem</b-form-select-option>
                <b-form-select-option value="BIconMoisture">Moisture</b-form-select-option>
                <b-form-select-option value="BIconMoonFill">MoonFill</b-form-select-option>
                <b-form-select-option value="BIconMoonStarsFill">MoonStarsFill</b-form-select-option>
                <b-form-select-option value="BIconMoonStars">MoonStars</b-form-select-option>
                <b-form-select-option value="BIconMoon">Moon</b-form-select-option>
                <b-form-select-option value="BIconMortarboardFill">MortarboardFill</b-form-select-option>
                <b-form-select-option value="BIconMortarboard">Mortarboard</b-form-select-option>
                <b-form-select-option value="BIconMotherboardFill">MotherboardFill</b-form-select-option>
                <b-form-select-option value="BIconMotherboard">Motherboard</b-form-select-option>
                <b-form-select-option value="BIconMouseFill">MouseFill</b-form-select-option>
                <b-form-select-option value="BIconMouse">Mouse</b-form-select-option>
                <b-form-select-option value="BIconMouse2Fill">Mouse2Fill</b-form-select-option>
                <b-form-select-option value="BIconMouse2">Mouse2</b-form-select-option>
                <b-form-select-option value="BIconMouse3Fill">Mouse3Fill</b-form-select-option>
                <b-form-select-option value="BIconMouse3">Mouse3</b-form-select-option>
                <b-form-select-option value="BIconMusicNoteBeamed">MusicNoteBeamed</b-form-select-option>
                <b-form-select-option value="BIconMusicNoteList">MusicNoteList</b-form-select-option>
                <b-form-select-option value="BIconMusicNote">MusicNote</b-form-select-option>
                <b-form-select-option value="BIconMusicPlayerFill">MusicPlayerFill</b-form-select-option>
                <b-form-select-option value="BIconMusicPlayer">MusicPlayer</b-form-select-option>
                <b-form-select-option value="BIconNewspaper">Newspaper</b-form-select-option>
                <b-form-select-option value="BIconNintendoSwitch">NintendoSwitch</b-form-select-option>
                <b-form-select-option value="BIconNodeMinusFill">NodeMinusFill</b-form-select-option>
                <b-form-select-option value="BIconNodeMinus">NodeMinus</b-form-select-option>
                <b-form-select-option value="BIconNodePlusFill">NodePlusFill</b-form-select-option>
                <b-form-select-option value="BIconNodePlus">NodePlus</b-form-select-option>
                <b-form-select-option value="BIconNutFill">NutFill</b-form-select-option>
                <b-form-select-option value="BIconNut">Nut</b-form-select-option>
                <b-form-select-option value="BIconOctagonFill">OctagonFill</b-form-select-option>
                <b-form-select-option value="BIconOctagonHalf">OctagonHalf</b-form-select-option>
                <b-form-select-option value="BIconOctagon">Octagon</b-form-select-option>
                <b-form-select-option value="BIconOpticalAudioFill">OpticalAudioFill</b-form-select-option>
                <b-form-select-option value="BIconOpticalAudio">OpticalAudio</b-form-select-option>
                <b-form-select-option value="BIconOption">Option</b-form-select-option>
                <b-form-select-option value="BIconOutlet">Outlet</b-form-select-option>
                <b-form-select-option value="BIconPaintBucket">PaintBucket</b-form-select-option>
                <b-form-select-option value="BIconPaletteFill">PaletteFill</b-form-select-option>
                <b-form-select-option value="BIconPalette">Palette</b-form-select-option>
                <b-form-select-option value="BIconPalette2">Palette2</b-form-select-option>
                <b-form-select-option value="BIconPaperclip">Paperclip</b-form-select-option>
                <b-form-select-option value="BIconParagraph">Paragraph</b-form-select-option>
                <b-form-select-option value="BIconPatchCheckFill">PatchCheckFill</b-form-select-option>
                <b-form-select-option value="BIconPatchCheck">PatchCheck</b-form-select-option>
                <b-form-select-option value="BIconPatchExclamationFill">PatchExclamationFill</b-form-select-option>
                <b-form-select-option value="BIconPatchExclamation">PatchExclamation</b-form-select-option>
                <b-form-select-option value="BIconPatchMinusFill">PatchMinusFill</b-form-select-option>
                <b-form-select-option value="BIconPatchMinus">PatchMinus</b-form-select-option>
                <b-form-select-option value="BIconPatchPlusFill">PatchPlusFill</b-form-select-option>
                <b-form-select-option value="BIconPatchPlus">PatchPlus</b-form-select-option>
                <b-form-select-option value="BIconPatchQuestionFill">PatchQuestionFill</b-form-select-option>
                <b-form-select-option value="BIconPatchQuestion">PatchQuestion</b-form-select-option>
                <b-form-select-option value="BIconPauseBtnFill">PauseBtnFill</b-form-select-option>
                <b-form-select-option value="BIconPauseBtn">PauseBtn</b-form-select-option>
                <b-form-select-option value="BIconPauseCircleFill">PauseCircleFill</b-form-select-option>
                <b-form-select-option value="BIconPauseCircle">PauseCircle</b-form-select-option>
                <b-form-select-option value="BIconPauseFill">PauseFill</b-form-select-option>
                <b-form-select-option value="BIconPause">Pause</b-form-select-option>
                <b-form-select-option value="BIconPaypal">Paypal</b-form-select-option>
                <b-form-select-option value="BIconPcDisplayHorizontal">PcDisplayHorizontal</b-form-select-option>
                <b-form-select-option value="BIconPcDisplay">PcDisplay</b-form-select-option>
                <b-form-select-option value="BIconPcHorizontal">PcHorizontal</b-form-select-option>
                <b-form-select-option value="BIconPc">Pc</b-form-select-option>
                <b-form-select-option value="BIconPciCard">PciCard</b-form-select-option>
                <b-form-select-option value="BIconPeaceFill">PeaceFill</b-form-select-option>
                <b-form-select-option value="BIconPeace">Peace</b-form-select-option>
                <b-form-select-option value="BIconPenFill">PenFill</b-form-select-option>
                <b-form-select-option value="BIconPen">Pen</b-form-select-option>
                <b-form-select-option value="BIconPencilFill">PencilFill</b-form-select-option>
                <b-form-select-option value="BIconPencilSquare">PencilSquare</b-form-select-option>
                <b-form-select-option value="BIconPencil">Pencil</b-form-select-option>
                <b-form-select-option value="BIconPentagonFill">PentagonFill</b-form-select-option>
                <b-form-select-option value="BIconPentagonHalf">PentagonHalf</b-form-select-option>
                <b-form-select-option value="BIconPentagon">Pentagon</b-form-select-option>
                <b-form-select-option value="BIconPeopleFill">PeopleFill</b-form-select-option>
                <b-form-select-option value="BIconPeople">People</b-form-select-option>
                <b-form-select-option value="BIconPercent">Percent</b-form-select-option>
                <b-form-select-option value="BIconPersonBadgeFill">PersonBadgeFill</b-form-select-option>
                <b-form-select-option value="BIconPersonBadge">PersonBadge</b-form-select-option>
                <b-form-select-option value="BIconPersonBoundingBox">PersonBoundingBox</b-form-select-option>
                <b-form-select-option value="BIconPersonCheckFill">PersonCheckFill</b-form-select-option>
                <b-form-select-option value="BIconPersonCheck">PersonCheck</b-form-select-option>
                <b-form-select-option value="BIconPersonCircle">PersonCircle</b-form-select-option>
                <b-form-select-option value="BIconPersonDashFill">PersonDashFill</b-form-select-option>
                <b-form-select-option value="BIconPersonDash">PersonDash</b-form-select-option>
                <b-form-select-option value="BIconPersonFill">PersonFill</b-form-select-option>
                <b-form-select-option value="BIconPersonLinesFill">PersonLinesFill</b-form-select-option>
                <b-form-select-option value="BIconPersonPlusFill">PersonPlusFill</b-form-select-option>
                <b-form-select-option value="BIconPersonPlus">PersonPlus</b-form-select-option>
                <b-form-select-option value="BIconPersonRolodex">PersonRolodex</b-form-select-option>
                <b-form-select-option value="BIconPersonSquare">PersonSquare</b-form-select-option>
                <b-form-select-option value="BIconPersonVideo">PersonVideo</b-form-select-option>
                <b-form-select-option value="BIconPersonVideo2">PersonVideo2</b-form-select-option>
                <b-form-select-option value="BIconPersonVideo3">PersonVideo3</b-form-select-option>
                <b-form-select-option value="BIconPersonWorkspace">PersonWorkspace</b-form-select-option>
                <b-form-select-option value="BIconPersonXFill">PersonXFill</b-form-select-option>
                <b-form-select-option value="BIconPersonX">PersonX</b-form-select-option>
                <b-form-select-option value="BIconPerson">Person</b-form-select-option>
                <b-form-select-option value="BIconPhoneFill">PhoneFill</b-form-select-option>
                <b-form-select-option value="BIconPhoneLandscapeFill">PhoneLandscapeFill</b-form-select-option>
                <b-form-select-option value="BIconPhoneLandscape">PhoneLandscape</b-form-select-option>
                <b-form-select-option value="BIconPhoneVibrateFill">PhoneVibrateFill</b-form-select-option>
                <b-form-select-option value="BIconPhoneVibrate">PhoneVibrate</b-form-select-option>
                <b-form-select-option value="BIconPhone">Phone</b-form-select-option>
                <b-form-select-option value="BIconPieChartFill">PieChartFill</b-form-select-option>
                <b-form-select-option value="BIconPieChart">PieChart</b-form-select-option>
                <b-form-select-option value="BIconPiggyBankFill">PiggyBankFill</b-form-select-option>
                <b-form-select-option value="BIconPiggyBank">PiggyBank</b-form-select-option>
                <b-form-select-option value="BIconPinAngleFill">PinAngleFill</b-form-select-option>
                <b-form-select-option value="BIconPinAngle">PinAngle</b-form-select-option>
                <b-form-select-option value="BIconPinFill">PinFill</b-form-select-option>
                <b-form-select-option value="BIconPinMapFill">PinMapFill</b-form-select-option>
                <b-form-select-option value="BIconPinMap">PinMap</b-form-select-option>
                <b-form-select-option value="BIconPin">Pin</b-form-select-option>
                <b-form-select-option value="BIconPinterest">Pinterest</b-form-select-option>
                <b-form-select-option value="BIconPipFill">PipFill</b-form-select-option>
                <b-form-select-option value="BIconPip">Pip</b-form-select-option>
                <b-form-select-option value="BIconPlayBtnFill">PlayBtnFill</b-form-select-option>
                <b-form-select-option value="BIconPlayBtn">PlayBtn</b-form-select-option>
                <b-form-select-option value="BIconPlayCircleFill">PlayCircleFill</b-form-select-option>
                <b-form-select-option value="BIconPlayCircle">PlayCircle</b-form-select-option>
                <b-form-select-option value="BIconPlayFill">PlayFill</b-form-select-option>
                <b-form-select-option value="BIconPlay">Play</b-form-select-option>
                <b-form-select-option value="BIconPlaystation">Playstation</b-form-select-option>
                <b-form-select-option value="BIconPlugFill">PlugFill</b-form-select-option>
                <b-form-select-option value="BIconPlug">Plug</b-form-select-option>
                <b-form-select-option value="BIconPlusCircleDotted">PlusCircleDotted</b-form-select-option>
                <b-form-select-option value="BIconPlusCircleFill">PlusCircleFill</b-form-select-option>
                <b-form-select-option value="BIconPlusCircle">PlusCircle</b-form-select-option>
                <b-form-select-option value="BIconPlusLg">PlusLg</b-form-select-option>
                <b-form-select-option value="BIconPlusSlashMinus">PlusSlashMinus</b-form-select-option>
                <b-form-select-option value="BIconPlusSquareDotted">PlusSquareDotted</b-form-select-option>
                <b-form-select-option value="BIconPlusSquareFill">PlusSquareFill</b-form-select-option>
                <b-form-select-option value="BIconPlusSquare">PlusSquare</b-form-select-option>
                <b-form-select-option value="BIconPlus">Plus</b-form-select-option>
                <b-form-select-option value="BIconPower">Power</b-form-select-option>
                <b-form-select-option value="BIconPrinterFill">PrinterFill</b-form-select-option>
                <b-form-select-option value="BIconPrinter">Printer</b-form-select-option>
                <b-form-select-option value="BIconProjectorFill">ProjectorFill</b-form-select-option>
                <b-form-select-option value="BIconProjector">Projector</b-form-select-option>
                <b-form-select-option value="BIconPuzzleFill">PuzzleFill</b-form-select-option>
                <b-form-select-option value="BIconPuzzle">Puzzle</b-form-select-option>
                <b-form-select-option value="BIconQrCodeScan">QrCodeScan</b-form-select-option>
                <b-form-select-option value="BIconQrCode">QrCode</b-form-select-option>
                <b-form-select-option value="BIconQuestionCircleFill">QuestionCircleFill</b-form-select-option>
                <b-form-select-option value="BIconQuestionCircle">QuestionCircle</b-form-select-option>
                <b-form-select-option value="BIconQuestionDiamondFill">QuestionDiamondFill</b-form-select-option>
                <b-form-select-option value="BIconQuestionDiamond">QuestionDiamond</b-form-select-option>
                <b-form-select-option value="BIconQuestionLg">QuestionLg</b-form-select-option>
                <b-form-select-option value="BIconQuestionOctagonFill">QuestionOctagonFill</b-form-select-option>
                <b-form-select-option value="BIconQuestionOctagon">QuestionOctagon</b-form-select-option>
                <b-form-select-option value="BIconQuestionSquareFill">QuestionSquareFill</b-form-select-option>
                <b-form-select-option value="BIconQuestionSquare">QuestionSquare</b-form-select-option>
                <b-form-select-option value="BIconQuestion">Question</b-form-select-option>
                <b-form-select-option value="BIconQuora">Quora</b-form-select-option>
                <b-form-select-option value="BIconQuote">Quote</b-form-select-option>
                <b-form-select-option value="BIconRadioactive">Radioactive</b-form-select-option>
                <b-form-select-option value="BIconRainbow">Rainbow</b-form-select-option>
                <b-form-select-option value="BIconReceiptCutoff">ReceiptCutoff</b-form-select-option>
                <b-form-select-option value="BIconReceipt">Receipt</b-form-select-option>
                <b-form-select-option value="BIconReception0">Reception0</b-form-select-option>
                <b-form-select-option value="BIconReception1">Reception1</b-form-select-option>
                <b-form-select-option value="BIconReception2">Reception2</b-form-select-option>
                <b-form-select-option value="BIconReception3">Reception3</b-form-select-option>
                <b-form-select-option value="BIconReception4">Reception4</b-form-select-option>
                <b-form-select-option value="BIconRecordBtnFill">RecordBtnFill</b-form-select-option>
                <b-form-select-option value="BIconRecordBtn">RecordBtn</b-form-select-option>
                <b-form-select-option value="BIconRecordCircleFill">RecordCircleFill</b-form-select-option>
                <b-form-select-option value="BIconRecordCircle">RecordCircle</b-form-select-option>
                <b-form-select-option value="BIconRecordFill">RecordFill</b-form-select-option>
                <b-form-select-option value="BIconRecord">Record</b-form-select-option>
                <b-form-select-option value="BIconRecord2Fill">Record2Fill</b-form-select-option>
                <b-form-select-option value="BIconRecord2">Record2</b-form-select-option>
                <b-form-select-option value="BIconRecycle">Recycle</b-form-select-option>
                <b-form-select-option value="BIconReddit">Reddit</b-form-select-option>
                <b-form-select-option value="BIconReplyAllFill">ReplyAllFill</b-form-select-option>
                <b-form-select-option value="BIconReplyAll">ReplyAll</b-form-select-option>
                <b-form-select-option value="BIconReplyFill">ReplyFill</b-form-select-option>
                <b-form-select-option value="BIconReply">Reply</b-form-select-option>
                <b-form-select-option value="BIconRobot">Robot</b-form-select-option>
                <b-form-select-option value="BIconRouterFill">RouterFill</b-form-select-option>
                <b-form-select-option value="BIconRouter">Router</b-form-select-option>
                <b-form-select-option value="BIconRssFill">RssFill</b-form-select-option>
                <b-form-select-option value="BIconRss">Rss</b-form-select-option>
                <b-form-select-option value="BIconRulers">Rulers</b-form-select-option>
                <b-form-select-option value="BIconSafeFill">SafeFill</b-form-select-option>
                <b-form-select-option value="BIconSafe">Safe</b-form-select-option>
                <b-form-select-option value="BIconSafe2Fill">Safe2Fill</b-form-select-option>
                <b-form-select-option value="BIconSafe2">Safe2</b-form-select-option>
                <b-form-select-option value="BIconSaveFill">SaveFill</b-form-select-option>
                <b-form-select-option value="BIconSave">Save</b-form-select-option>
                <b-form-select-option value="BIconSave2Fill">Save2Fill</b-form-select-option>
                <b-form-select-option value="BIconSave2">Save2</b-form-select-option>
                <b-form-select-option value="BIconScissors">Scissors</b-form-select-option>
                <b-form-select-option value="BIconScrewdriver">Screwdriver</b-form-select-option>
                <b-form-select-option value="BIconSdCardFill">SdCardFill</b-form-select-option>
                <b-form-select-option value="BIconSdCard">SdCard</b-form-select-option>
                <b-form-select-option value="BIconSearch">Search</b-form-select-option>
                <b-form-select-option value="BIconSegmentedNav">SegmentedNav</b-form-select-option>
                <b-form-select-option value="BIconSendCheckFill">SendCheckFill</b-form-select-option>
                <b-form-select-option value="BIconSendCheck">SendCheck</b-form-select-option>
                <b-form-select-option value="BIconSendDashFill">SendDashFill</b-form-select-option>
                <b-form-select-option value="BIconSendDash">SendDash</b-form-select-option>
                <b-form-select-option value="BIconSendExclamationFill">SendExclamationFill</b-form-select-option>
                <b-form-select-option value="BIconSendExclamation">SendExclamation</b-form-select-option>
                <b-form-select-option value="BIconSendFill">SendFill</b-form-select-option>
                <b-form-select-option value="BIconSendPlusFill">SendPlusFill</b-form-select-option>
                <b-form-select-option value="BIconSendPlus">SendPlus</b-form-select-option>
                <b-form-select-option value="BIconSendSlashFill">SendSlashFill</b-form-select-option>
                <b-form-select-option value="BIconSendSlash">SendSlash</b-form-select-option>
                <b-form-select-option value="BIconSendXFill">SendXFill</b-form-select-option>
                <b-form-select-option value="BIconSendX">SendX</b-form-select-option>
                <b-form-select-option value="BIconSend">Send</b-form-select-option>
                <b-form-select-option value="BIconServer">Server</b-form-select-option>
                <b-form-select-option value="BIconShareFill">ShareFill</b-form-select-option>
                <b-form-select-option value="BIconShare">Share</b-form-select-option>
                <b-form-select-option value="BIconShieldCheck">ShieldCheck</b-form-select-option>
                <b-form-select-option value="BIconShieldExclamation">ShieldExclamation</b-form-select-option>
                <b-form-select-option value="BIconShieldFillCheck">ShieldFillCheck</b-form-select-option>
                <b-form-select-option value="BIconShieldFillExclamation">ShieldFillExclamation</b-form-select-option>
                <b-form-select-option value="BIconShieldFillMinus">ShieldFillMinus</b-form-select-option>
                <b-form-select-option value="BIconShieldFillPlus">ShieldFillPlus</b-form-select-option>
                <b-form-select-option value="BIconShieldFillX">ShieldFillX</b-form-select-option>
                <b-form-select-option value="BIconShieldFill">ShieldFill</b-form-select-option>
                <b-form-select-option value="BIconShieldLockFill">ShieldLockFill</b-form-select-option>
                <b-form-select-option value="BIconShieldLock">ShieldLock</b-form-select-option>
                <b-form-select-option value="BIconShieldMinus">ShieldMinus</b-form-select-option>
                <b-form-select-option value="BIconShieldPlus">ShieldPlus</b-form-select-option>
                <b-form-select-option value="BIconShieldShaded">ShieldShaded</b-form-select-option>
                <b-form-select-option value="BIconShieldSlashFill">ShieldSlashFill</b-form-select-option>
                <b-form-select-option value="BIconShieldSlash">ShieldSlash</b-form-select-option>
                <b-form-select-option value="BIconShieldX">ShieldX</b-form-select-option>
                <b-form-select-option value="BIconShield">Shield</b-form-select-option>
                <b-form-select-option value="BIconShiftFill">ShiftFill</b-form-select-option>
                <b-form-select-option value="BIconShift">Shift</b-form-select-option>
                <b-form-select-option value="BIconShopWindow">ShopWindow</b-form-select-option>
                <b-form-select-option value="BIconShop">Shop</b-form-select-option>
                <b-form-select-option value="BIconShuffle">Shuffle</b-form-select-option>
                <b-form-select-option value="BIconSignal">Signal</b-form-select-option>
                <b-form-select-option value="BIconSignpost2Fill">Signpost2Fill</b-form-select-option>
                <b-form-select-option value="BIconSignpost2">Signpost2</b-form-select-option>
                <b-form-select-option value="BIconSignpostFill">SignpostFill</b-form-select-option>
                <b-form-select-option value="BIconSignpostSplitFill">SignpostSplitFill</b-form-select-option>
                <b-form-select-option value="BIconSignpostSplit">SignpostSplit</b-form-select-option>
                <b-form-select-option value="BIconSignpost">Signpost</b-form-select-option>
                <b-form-select-option value="BIconSimFill">SimFill</b-form-select-option>
                <b-form-select-option value="BIconSim">Sim</b-form-select-option>
                <b-form-select-option value="BIconSkipBackwardBtnFill">SkipBackwardBtnFill</b-form-select-option>
                <b-form-select-option value="BIconSkipBackwardBtn">SkipBackwardBtn</b-form-select-option>
                <b-form-select-option value="BIconSkipBackwardCircleFill">SkipBackwardCircleFill</b-form-select-option>
                <b-form-select-option value="BIconSkipBackwardCircle">SkipBackwardCircle</b-form-select-option>
                <b-form-select-option value="BIconSkipBackwardFill">SkipBackwardFill</b-form-select-option>
                <b-form-select-option value="BIconSkipBackward">SkipBackward</b-form-select-option>
                <b-form-select-option value="BIconSkipEndBtnFill">SkipEndBtnFill</b-form-select-option>
                <b-form-select-option value="BIconSkipEndBtn">SkipEndBtn</b-form-select-option>
                <b-form-select-option value="BIconSkipEndCircleFill">SkipEndCircleFill</b-form-select-option>
                <b-form-select-option value="BIconSkipEndCircle">SkipEndCircle</b-form-select-option>
                <b-form-select-option value="BIconSkipEndFill">SkipEndFill</b-form-select-option>
                <b-form-select-option value="BIconSkipEnd">SkipEnd</b-form-select-option>
                <b-form-select-option value="BIconSkipForwardBtnFill">SkipForwardBtnFill</b-form-select-option>
                <b-form-select-option value="BIconSkipForwardBtn">SkipForwardBtn</b-form-select-option>
                <b-form-select-option value="BIconSkipForwardCircleFill">SkipForwardCircleFill</b-form-select-option>
                <b-form-select-option value="BIconSkipForwardCircle">SkipForwardCircle</b-form-select-option>
                <b-form-select-option value="BIconSkipForwardFill">SkipForwardFill</b-form-select-option>
                <b-form-select-option value="BIconSkipForward">SkipForward</b-form-select-option>
                <b-form-select-option value="BIconSkipStartBtnFill">SkipStartBtnFill</b-form-select-option>
                <b-form-select-option value="BIconSkipStartBtn">SkipStartBtn</b-form-select-option>
                <b-form-select-option value="BIconSkipStartCircleFill">SkipStartCircleFill</b-form-select-option>
                <b-form-select-option value="BIconSkipStartCircle">SkipStartCircle</b-form-select-option>
                <b-form-select-option value="BIconSkipStartFill">SkipStartFill</b-form-select-option>
                <b-form-select-option value="BIconSkipStart">SkipStart</b-form-select-option>
                <b-form-select-option value="BIconSkype">Skype</b-form-select-option>
                <b-form-select-option value="BIconSlack">Slack</b-form-select-option>
                <b-form-select-option value="BIconSlashCircleFill">SlashCircleFill</b-form-select-option>
                <b-form-select-option value="BIconSlashCircle">SlashCircle</b-form-select-option>
                <b-form-select-option value="BIconSlashLg">SlashLg</b-form-select-option>
                <b-form-select-option value="BIconSlashSquareFill">SlashSquareFill</b-form-select-option>
                <b-form-select-option value="BIconSlashSquare">SlashSquare</b-form-select-option>
                <b-form-select-option value="BIconSlash">Slash</b-form-select-option>
                <b-form-select-option value="BIconSliders">Sliders</b-form-select-option>
                <b-form-select-option value="BIconSmartwatch">Smartwatch</b-form-select-option>
                <b-form-select-option value="BIconSnapchat">Snapchat</b-form-select-option>
                <b-form-select-option value="BIconSnow">Snow</b-form-select-option>
                <b-form-select-option value="BIconSnow2">Snow2</b-form-select-option>
                <b-form-select-option value="BIconSnow3">Snow3</b-form-select-option>
                <b-form-select-option value="BIconSortAlphaDownAlt">SortAlphaDownAlt</b-form-select-option>
                <b-form-select-option value="BIconSortAlphaDown">SortAlphaDown</b-form-select-option>
                <b-form-select-option value="BIconSortAlphaUpAlt">SortAlphaUpAlt</b-form-select-option>
                <b-form-select-option value="BIconSortAlphaUp">SortAlphaUp</b-form-select-option>
                <b-form-select-option value="BIconSortDownAlt">SortDownAlt</b-form-select-option>
                <b-form-select-option value="BIconSortDown">SortDown</b-form-select-option>
                <b-form-select-option value="BIconSortNumericDownAlt">SortNumericDownAlt</b-form-select-option>
                <b-form-select-option value="BIconSortNumericDown">SortNumericDown</b-form-select-option>
                <b-form-select-option value="BIconSortNumericUpAlt">SortNumericUpAlt</b-form-select-option>
                <b-form-select-option value="BIconSortNumericUp">SortNumericUp</b-form-select-option>
                <b-form-select-option value="BIconSortUpAlt">SortUpAlt</b-form-select-option>
                <b-form-select-option value="BIconSortUp">SortUp</b-form-select-option>
                <b-form-select-option value="BIconSoundwave">Soundwave</b-form-select-option>
                <b-form-select-option value="BIconSpeakerFill">SpeakerFill</b-form-select-option>
                <b-form-select-option value="BIconSpeaker">Speaker</b-form-select-option>
                <b-form-select-option value="BIconSpeedometer">Speedometer</b-form-select-option>
                <b-form-select-option value="BIconSpeedometer2">Speedometer2</b-form-select-option>
                <b-form-select-option value="BIconSpellcheck">Spellcheck</b-form-select-option>
                <b-form-select-option value="BIconSpotify">Spotify</b-form-select-option>
                <b-form-select-option value="BIconSquareFill">SquareFill</b-form-select-option>
                <b-form-select-option value="BIconSquareHalf">SquareHalf</b-form-select-option>
                <b-form-select-option value="BIconSquare">Square</b-form-select-option>
                <b-form-select-option value="BIconStackOverflow">StackOverflow</b-form-select-option>
                <b-form-select-option value="BIconStack">Stack</b-form-select-option>
                <b-form-select-option value="BIconStarFill">StarFill</b-form-select-option>
                <b-form-select-option value="BIconStarHalf">StarHalf</b-form-select-option>
                <b-form-select-option value="BIconStar">Star</b-form-select-option>
                <b-form-select-option value="BIconStars">Stars</b-form-select-option>
                <b-form-select-option value="BIconSteam">Steam</b-form-select-option>
                <b-form-select-option value="BIconStickiesFill">StickiesFill</b-form-select-option>
                <b-form-select-option value="BIconStickies">Stickies</b-form-select-option>
                <b-form-select-option value="BIconStickyFill">StickyFill</b-form-select-option>
                <b-form-select-option value="BIconSticky">Sticky</b-form-select-option>
                <b-form-select-option value="BIconStopBtnFill">StopBtnFill</b-form-select-option>
                <b-form-select-option value="BIconStopBtn">StopBtn</b-form-select-option>
                <b-form-select-option value="BIconStopCircleFill">StopCircleFill</b-form-select-option>
                <b-form-select-option value="BIconStopCircle">StopCircle</b-form-select-option>
                <b-form-select-option value="BIconStopFill">StopFill</b-form-select-option>
                <b-form-select-option value="BIconStop">Stop</b-form-select-option>
                <b-form-select-option value="BIconStoplightsFill">StoplightsFill</b-form-select-option>
                <b-form-select-option value="BIconStoplights">Stoplights</b-form-select-option>
                <b-form-select-option value="BIconStopwatchFill">StopwatchFill</b-form-select-option>
                <b-form-select-option value="BIconStopwatch">Stopwatch</b-form-select-option>
                <b-form-select-option value="BIconStrava">Strava</b-form-select-option>
                <b-form-select-option value="BIconSubtract">Subtract</b-form-select-option>
                <b-form-select-option value="BIconSuitClubFill">SuitClubFill</b-form-select-option>
                <b-form-select-option value="BIconSuitClub">SuitClub</b-form-select-option>
                <b-form-select-option value="BIconSuitDiamondFill">SuitDiamondFill</b-form-select-option>
                <b-form-select-option value="BIconSuitDiamond">SuitDiamond</b-form-select-option>
                <b-form-select-option value="BIconSuitHeartFill">SuitHeartFill</b-form-select-option>
                <b-form-select-option value="BIconSuitHeart">SuitHeart</b-form-select-option>
                <b-form-select-option value="BIconSuitSpadeFill">SuitSpadeFill</b-form-select-option>
                <b-form-select-option value="BIconSuitSpade">SuitSpade</b-form-select-option>
                <b-form-select-option value="BIconSunFill">SunFill</b-form-select-option>
                <b-form-select-option value="BIconSun">Sun</b-form-select-option>
                <b-form-select-option value="BIconSunglasses">Sunglasses</b-form-select-option>
                <b-form-select-option value="BIconSunriseFill">SunriseFill</b-form-select-option>
                <b-form-select-option value="BIconSunrise">Sunrise</b-form-select-option>
                <b-form-select-option value="BIconSunsetFill">SunsetFill</b-form-select-option>
                <b-form-select-option value="BIconSunset">Sunset</b-form-select-option>
                <b-form-select-option value="BIconSymmetryHorizontal">SymmetryHorizontal</b-form-select-option>
                <b-form-select-option value="BIconSymmetryVertical">SymmetryVertical</b-form-select-option>
                <b-form-select-option value="BIconTable">Table</b-form-select-option>
                <b-form-select-option value="BIconTabletFill">TabletFill</b-form-select-option>
                <b-form-select-option value="BIconTabletLandscapeFill">TabletLandscapeFill</b-form-select-option>
                <b-form-select-option value="BIconTabletLandscape">TabletLandscape</b-form-select-option>
                <b-form-select-option value="BIconTablet">Tablet</b-form-select-option>
                <b-form-select-option value="BIconTagFill">TagFill</b-form-select-option>
                <b-form-select-option value="BIconTag">Tag</b-form-select-option>
                <b-form-select-option value="BIconTagsFill">TagsFill</b-form-select-option>
                <b-form-select-option value="BIconTags">Tags</b-form-select-option>
                <b-form-select-option value="BIconTelegram">Telegram</b-form-select-option>
                <b-form-select-option value="BIconTelephoneFill">TelephoneFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneForwardFill">TelephoneForwardFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneForward">TelephoneForward</b-form-select-option>
                <b-form-select-option value="BIconTelephoneInboundFill">TelephoneInboundFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneInbound">TelephoneInbound</b-form-select-option>
                <b-form-select-option value="BIconTelephoneMinusFill">TelephoneMinusFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneMinus">TelephoneMinus</b-form-select-option>
                <b-form-select-option value="BIconTelephoneOutboundFill">TelephoneOutboundFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneOutbound">TelephoneOutbound</b-form-select-option>
                <b-form-select-option value="BIconTelephonePlusFill">TelephonePlusFill</b-form-select-option>
                <b-form-select-option value="BIconTelephonePlus">TelephonePlus</b-form-select-option>
                <b-form-select-option value="BIconTelephoneXFill">TelephoneXFill</b-form-select-option>
                <b-form-select-option value="BIconTelephoneX">TelephoneX</b-form-select-option>
                <b-form-select-option value="BIconTelephone">Telephone</b-form-select-option>
                <b-form-select-option value="BIconTerminalDash">TerminalDash</b-form-select-option>
                <b-form-select-option value="BIconTerminalFill">TerminalFill</b-form-select-option>
                <b-form-select-option value="BIconTerminalPlus">TerminalPlus</b-form-select-option>
                <b-form-select-option value="BIconTerminalSplit">TerminalSplit</b-form-select-option>
                <b-form-select-option value="BIconTerminalX">TerminalX</b-form-select-option>
                <b-form-select-option value="BIconTerminal">Terminal</b-form-select-option>
                <b-form-select-option value="BIconTextCenter">TextCenter</b-form-select-option>
                <b-form-select-option value="BIconTextIndentLeft">TextIndentLeft</b-form-select-option>
                <b-form-select-option value="BIconTextIndentRight">TextIndentRight</b-form-select-option>
                <b-form-select-option value="BIconTextLeft">TextLeft</b-form-select-option>
                <b-form-select-option value="BIconTextParagraph">TextParagraph</b-form-select-option>
                <b-form-select-option value="BIconTextRight">TextRight</b-form-select-option>
                <b-form-select-option value="BIconTextareaResize">TextareaResize</b-form-select-option>
                <b-form-select-option value="BIconTextareaT">TextareaT</b-form-select-option>
                <b-form-select-option value="BIconTextarea">Textarea</b-form-select-option>
                <b-form-select-option value="BIconThermometerHalf">ThermometerHalf</b-form-select-option>
                <b-form-select-option value="BIconThermometerHigh">ThermometerHigh</b-form-select-option>
                <b-form-select-option value="BIconThermometerLow">ThermometerLow</b-form-select-option>
                <b-form-select-option value="BIconThermometerSnow">ThermometerSnow</b-form-select-option>
                <b-form-select-option value="BIconThermometerSun">ThermometerSun</b-form-select-option>
                <b-form-select-option value="BIconThermometer">Thermometer</b-form-select-option>
                <b-form-select-option value="BIconThreeDotsVertical">ThreeDotsVertical</b-form-select-option>
                <b-form-select-option value="BIconThreeDots">ThreeDots</b-form-select-option>
                <b-form-select-option value="BIconThunderboltFill">ThunderboltFill</b-form-select-option>
                <b-form-select-option value="BIconThunderbolt">Thunderbolt</b-form-select-option>
                <b-form-select-option value="BIconTicketDetailedFill">TicketDetailedFill</b-form-select-option>
                <b-form-select-option value="BIconTicketDetailed">TicketDetailed</b-form-select-option>
                <b-form-select-option value="BIconTicketFill">TicketFill</b-form-select-option>
                <b-form-select-option value="BIconTicketPerforatedFill">TicketPerforatedFill</b-form-select-option>
                <b-form-select-option value="BIconTicketPerforated">TicketPerforated</b-form-select-option>
                <b-form-select-option value="BIconTicket">Ticket</b-form-select-option>
                <b-form-select-option value="BIconTiktok">Tiktok</b-form-select-option>
                <b-form-select-option value="BIconToggleOff">ToggleOff</b-form-select-option>
                <b-form-select-option value="BIconToggleOn">ToggleOn</b-form-select-option>
                <b-form-select-option value="BIconToggle2Off">Toggle2Off</b-form-select-option>
                <b-form-select-option value="BIconToggle2On">Toggle2On</b-form-select-option>
                <b-form-select-option value="BIconToggles">Toggles</b-form-select-option>
                <b-form-select-option value="BIconToggles2">Toggles2</b-form-select-option>
                <b-form-select-option value="BIconTools">Tools</b-form-select-option>
                <b-form-select-option value="BIconTornado">Tornado</b-form-select-option>
                <b-form-select-option value="BIconTranslate">Translate</b-form-select-option>
                <b-form-select-option value="BIconTrashFill">TrashFill</b-form-select-option>
                <b-form-select-option value="BIconTrash">Trash</b-form-select-option>
                <b-form-select-option value="BIconTrash2Fill">Trash2Fill</b-form-select-option>
                <b-form-select-option value="BIconTrash2">Trash2</b-form-select-option>
                <b-form-select-option value="BIconTreeFill">TreeFill</b-form-select-option>
                <b-form-select-option value="BIconTree">Tree</b-form-select-option>
                <b-form-select-option value="BIconTriangleFill">TriangleFill</b-form-select-option>
                <b-form-select-option value="BIconTriangleHalf">TriangleHalf</b-form-select-option>
                <b-form-select-option value="BIconTriangle">Triangle</b-form-select-option>
                <b-form-select-option value="BIconTrophyFill">TrophyFill</b-form-select-option>
                <b-form-select-option value="BIconTrophy">Trophy</b-form-select-option>
                <b-form-select-option value="BIconTropicalStorm">TropicalStorm</b-form-select-option>
                <b-form-select-option value="BIconTruckFlatbed">TruckFlatbed</b-form-select-option>
                <b-form-select-option value="BIconTruck">Truck</b-form-select-option>
                <b-form-select-option value="BIconTsunami">Tsunami</b-form-select-option>
                <b-form-select-option value="BIconTvFill">TvFill</b-form-select-option>
                <b-form-select-option value="BIconTv">Tv</b-form-select-option>
                <b-form-select-option value="BIconTwitch">Twitch</b-form-select-option>
                <b-form-select-option value="BIconTwitter">Twitter</b-form-select-option>
                <b-form-select-option value="BIconTypeBold">TypeBold</b-form-select-option>
                <b-form-select-option value="BIconTypeH1">TypeH1</b-form-select-option>
                <b-form-select-option value="BIconTypeH2">TypeH2</b-form-select-option>
                <b-form-select-option value="BIconTypeH3">TypeH3</b-form-select-option>
                <b-form-select-option value="BIconTypeItalic">TypeItalic</b-form-select-option>
                <b-form-select-option value="BIconTypeStrikethrough">TypeStrikethrough</b-form-select-option>
                <b-form-select-option value="BIconTypeUnderline">TypeUnderline</b-form-select-option>
                <b-form-select-option value="BIconType">Type</b-form-select-option>
                <b-form-select-option value="BIconUiChecksGrid">UiChecksGrid</b-form-select-option>
                <b-form-select-option value="BIconUiChecks">UiChecks</b-form-select-option>
                <b-form-select-option value="BIconUiRadiosGrid">UiRadiosGrid</b-form-select-option>
                <b-form-select-option value="BIconUiRadios">UiRadios</b-form-select-option>
                <b-form-select-option value="BIconUmbrellaFill">UmbrellaFill</b-form-select-option>
                <b-form-select-option value="BIconUmbrella">Umbrella</b-form-select-option>
                <b-form-select-option value="BIconUnion">Union</b-form-select-option>
                <b-form-select-option value="BIconUnlockFill">UnlockFill</b-form-select-option>
                <b-form-select-option value="BIconUnlock">Unlock</b-form-select-option>
                <b-form-select-option value="BIconUpcScan">UpcScan</b-form-select-option>
                <b-form-select-option value="BIconUpc">Upc</b-form-select-option>
                <b-form-select-option value="BIconUpload">Upload</b-form-select-option>
                <b-form-select-option value="BIconUsbCFill">UsbCFill</b-form-select-option>
                <b-form-select-option value="BIconUsbC">UsbC</b-form-select-option>
                <b-form-select-option value="BIconUsbDriveFill">UsbDriveFill</b-form-select-option>
                <b-form-select-option value="BIconUsbDrive">UsbDrive</b-form-select-option>
                <b-form-select-option value="BIconUsbFill">UsbFill</b-form-select-option>
                <b-form-select-option value="BIconUsbMicroFill">UsbMicroFill</b-form-select-option>
                <b-form-select-option value="BIconUsbMicro">UsbMicro</b-form-select-option>
                <b-form-select-option value="BIconUsbMiniFill">UsbMiniFill</b-form-select-option>
                <b-form-select-option value="BIconUsbMini">UsbMini</b-form-select-option>
                <b-form-select-option value="BIconUsbPlugFill">UsbPlugFill</b-form-select-option>
                <b-form-select-option value="BIconUsbPlug">UsbPlug</b-form-select-option>
                <b-form-select-option value="BIconUsbSymbol">UsbSymbol</b-form-select-option>
                <b-form-select-option value="BIconUsb">Usb</b-form-select-option>
                <b-form-select-option value="BIconVectorPen">VectorPen</b-form-select-option>
                <b-form-select-option value="BIconViewList">ViewList</b-form-select-option>
                <b-form-select-option value="BIconViewStacked">ViewStacked</b-form-select-option>
                <b-form-select-option value="BIconVimeo">Vimeo</b-form-select-option>
                <b-form-select-option value="BIconVinylFill">VinylFill</b-form-select-option>
                <b-form-select-option value="BIconVinyl">Vinyl</b-form-select-option>
                <b-form-select-option value="BIconVoicemail">Voicemail</b-form-select-option>
                <b-form-select-option value="BIconVolumeDownFill">VolumeDownFill</b-form-select-option>
                <b-form-select-option value="BIconVolumeDown">VolumeDown</b-form-select-option>
                <b-form-select-option value="BIconVolumeMuteFill">VolumeMuteFill</b-form-select-option>
                <b-form-select-option value="BIconVolumeMute">VolumeMute</b-form-select-option>
                <b-form-select-option value="BIconVolumeOffFill">VolumeOffFill</b-form-select-option>
                <b-form-select-option value="BIconVolumeOff">VolumeOff</b-form-select-option>
                <b-form-select-option value="BIconVolumeUpFill">VolumeUpFill</b-form-select-option>
                <b-form-select-option value="BIconVolumeUp">VolumeUp</b-form-select-option>
                <b-form-select-option value="BIconVr">Vr</b-form-select-option>
                <b-form-select-option value="BIconWalletFill">WalletFill</b-form-select-option>
                <b-form-select-option value="BIconWallet">Wallet</b-form-select-option>
                <b-form-select-option value="BIconWallet2">Wallet2</b-form-select-option>
                <b-form-select-option value="BIconWatch">Watch</b-form-select-option>
                <b-form-select-option value="BIconWater">Water</b-form-select-option>
                <b-form-select-option value="BIconWebcamFill">WebcamFill</b-form-select-option>
                <b-form-select-option value="BIconWebcam">Webcam</b-form-select-option>
                <b-form-select-option value="BIconWhatsapp">Whatsapp</b-form-select-option>
                <b-form-select-option value="BIconWifi1">Wifi1</b-form-select-option>
                <b-form-select-option value="BIconWifi2">Wifi2</b-form-select-option>
                <b-form-select-option value="BIconWifiOff">WifiOff</b-form-select-option>
                <b-form-select-option value="BIconWifi">Wifi</b-form-select-option>
                <b-form-select-option value="BIconWind">Wind</b-form-select-option>
                <b-form-select-option value="BIconWindowDash">WindowDash</b-form-select-option>
                <b-form-select-option value="BIconWindowDesktop">WindowDesktop</b-form-select-option>
                <b-form-select-option value="BIconWindowDock">WindowDock</b-form-select-option>
                <b-form-select-option value="BIconWindowFullscreen">WindowFullscreen</b-form-select-option>
                <b-form-select-option value="BIconWindowPlus">WindowPlus</b-form-select-option>
                <b-form-select-option value="BIconWindowSidebar">WindowSidebar</b-form-select-option>
                <b-form-select-option value="BIconWindowSplit">WindowSplit</b-form-select-option>
                <b-form-select-option value="BIconWindowStack">WindowStack</b-form-select-option>
                <b-form-select-option value="BIconWindowX">WindowX</b-form-select-option>
                <b-form-select-option value="BIconWindow">Window</b-form-select-option>
                <b-form-select-option value="BIconWindows">Windows</b-form-select-option>
                <b-form-select-option value="BIconWordpress">Wordpress</b-form-select-option>
                <b-form-select-option value="BIconWrench">Wrench</b-form-select-option>
                <b-form-select-option value="BIconXCircleFill">XCircleFill</b-form-select-option>
                <b-form-select-option value="BIconXCircle">XCircle</b-form-select-option>
                <b-form-select-option value="BIconXDiamondFill">XDiamondFill</b-form-select-option>
                <b-form-select-option value="BIconXDiamond">XDiamond</b-form-select-option>
                <b-form-select-option value="BIconXLg">XLg</b-form-select-option>
                <b-form-select-option value="BIconXOctagonFill">XOctagonFill</b-form-select-option>
                <b-form-select-option value="BIconXOctagon">XOctagon</b-form-select-option>
                <b-form-select-option value="BIconXSquareFill">XSquareFill</b-form-select-option>
                <b-form-select-option value="BIconXSquare">XSquare</b-form-select-option>
                <b-form-select-option value="BIconX">X</b-form-select-option>
                <b-form-select-option value="BIconXbox">Xbox</b-form-select-option>
                <b-form-select-option value="BIconYinYang">YinYang</b-form-select-option>
                <b-form-select-option value="BIconYoutube">Youtube</b-form-select-option>
                <b-form-select-option value="BIconZoomIn">ZoomIn</b-form-select-option>
                <b-form-select-option value="BIconZoomOut">ZoomOut</b-form-select-option>
              </b-form-select>
              </b-col>

              <b-col cols="4">
                <label>Home Icon</label>
                <b-form-file v-model="file">
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                    <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                      + {{ names.length - 1 }} More files
                    </b-badge>
                  </template>
                </b-form-file>
              </b-col>
            <b-col cols="4" v-if="applicationData.icon != '' && applicationData.icon != null">
               <label>Icon on file</label><br/>
               <img width="65" height="65" v-bind:src="url + applicationData.icon"/>
             </b-col> 
            </b-row>

            <b-col>
              <div class="mt-4 mb-3">
                <label>Main Menu</label>
                  <div>
                    <b-form-checkbox v-model="applicationData.mainMenu" name="check-button" switch>

                    </b-form-checkbox>
                  </div>
              </div>
               <div>
                  <label>SideBar Menu </label>
                  <div>
                    <b-form-checkbox v-model="applicationData.sideBar" name="check-button" switch>

                    </b-form-checkbox>
                  </div>
               </div>
              </b-col>
            
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="updateApplicationAndServices()" class="ml-2">Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="userModal" hide-footer hide-header-close title="Delete User">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span
              class="font-weight-bold text-red text-center">{{this.applicationData.name}}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="removeApplicationAndService" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>
<style>
  .primary {
    background-color: #ffcb14;
    color: #222222;
  }

  .primary:hover {
    color: #fff;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
  }
</style>
<script>
  import {
    mapMutations,
    mapState
  } from "vuex";
  import {
    required
  } from "vuelidate/lib/validators";
  import api from "../../../api";
  import axios from "axios";

  export default {
    name: "applicationView",

    data: () => ({
      iconSelected: "",
      file: null,
      state: 'show',
      applicationData: {
        id: 0,
        name: null,
        target: null,
        type: "redirect",
        url: null,
        powerBiMobile: null,
        listOrder: 0,
        mainMenu: null,
        sideBar: null,
        sidebarIcon: "",
        groupingID: null,   
        grouping: [],
        icon: ""
      },
      grouping: [],
      targetOptions: [
        { value: 'internal', text: 'internal' },
        { value: 'external', text: 'external' },
      ],
      typeOptions: [
        { value: 'redirect', text: 'redirect' },
        { value: 'powerbi', text: 'PowerBi' },
      ],
      url: ""
    }),
    created() {
      this.url = api.Url;
      this.setBreadcrumb([{
          text: 'Link'
        },
        {
          text: 'Details'
        },
      ]);

    

      const e = (msg) => {
        console.log(msg);
      };

        const gs = (g) => {
          this.grouping = g;
           console.log( this.grouping);

         const s = (d) => {
        this.applicationData = d;
         this.applicationData.groupingID = this.applicationData.grouping.id;
        console.log(d);
        this.state = 'show';
      };

      this.applicationData.id = localStorage.getItem("view_link_id");
      api.getLinksID(localStorage.getItem("view_link_id"), s, e);
        };
      // this.loadGroupings();
      api.getGroupings(gs, e)
    },
    
    methods: {
    
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),

      // loadGroupings() {
      //   const s = (data) => {
      //     this.groupings = data;
      //     console.log(data);
      //   };

      //   const e = (msg) => {
      //     console.log(msg);
      //   };
      //   api.getGroupings(s, e);
      // },

      goBackToSearch() {
        this.$router.push({
          path: '/admin/ManageLinks/SearchLink'
        })
      },

      updateApplicationAndServices() {
        if(this.file == null) {
          const s = (data) => {
            this.$router.push({path: '/admin/ManageLinks/SearchLink'})
            console.log(data);
          };

          const e = (msg) => {
            console.log(msg);
          };

          console.log(this.applicationData);
          api.UpdateLinks(this.applicationData, s, e);
          return;
        }
          const formData = new FormData();
          formData.append("file", this.file);
          console.log(this.file)
          axios.post(api.Url + "/Cdn", formData, {
            headers: {
              Authorization : `Bearer ${localStorage.getItem("api_Key")}`,
              'Content-Type': 'multipart/form-data'
            }
          }).then((resp) => {


            console.log(this.file)
            this.applicationData.icon = "/Cdn/Get/" + resp.data + "/" + this.file.name;
            console.log(this.applicationData.icon)

            const s = (data) => {
              this.$router.push({path: '/admin/ManageLinks/SearchLink'})
              console.log(data);
            };

            const e = (msg) => {
              console.log(msg);
            };

            console.log(this.applicationData);
            this.applicationData.grouping = {
              "id": this.applicationData.groupingID
            };


            api.UpdateLinks(this.applicationData, s, e)
          }).catch((error) => {
            console.error(error);
          });

      },

      removeApplicationAndService() {   
        var s = (data) => {
          console.log(data)
        };
        var e = (error) => {
          console.log(error)
        };

        api.DeleteLinks(this.applicationData, s, e)
        this.hideDeleteModal();
        // window.location = '/admin/ManageLinks/SearchLink';
        this.$router.push({path: "/admin/ManageLinks/SearchLink"})
      },


      openDeleteModal() {
        this.$bvModal.show('userModal')
      },

      hideDeleteModal() {
        this.$bvModal.hide('userModal')
      },
    },
    computed: {
      ...mapState([
        'SelectedApplicationAndService'
      ])
    },
    validations: {
      SelectedApplicationAndService: {
        name: {
          required
        },
      },
    },
  }
</script>

<style scoped>

</style>